<template>
  <div class="px-4">
    <div v-if="reverting" class="h-16 flex items-center ml-4">
      <svg class="animate-spin w-5 h-5" viewBox="0 0 24 24">
        <path d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>
    <div v-else-if="generations.length" class="flex flex-col space-y-4">
      <div
        v-for="generation in generations"
        :key="generation.id"
        class="flex items-center"
        style="height: 57px"
      >
        <div class="font-medium text-md mr-2" v-text="`v${generation.version}`" />
        <div class="pl-4 border-l-2 h-full" :class="generationId === generation.id ? 'border-info' : 'border-transparent'">
          <div class="flex items-center">
            <div class="font-medium">{{ generation.created.time | formatTimestamp('yyyy-MM-dd HH:mm:ss') }}</div>
            <div v-if="generation.active" class="ml-2 px-1 leading-relaxed bg-warning text-white rounded-md text-xs">Active</div>
          </div>
          <div class="text-muted truncate">Created by {{ $store.getters['organization/getUserName'](generation.created.uid) }}</div>
          <div class="flex items-center space-x-4">
            <router-link
              v-if="generation.id !== generationId"
              :to="`/library/${objectId}?generation=${generation.id}`"
              class="text-info font-medium"
            >
              View
            </router-link>
            <div
              v-if="!generation.active"
              class="text-info font-medium hover:cursor-pointer"
              @click="revert(generation.id)"
            >
              Make active
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      No versions
    </div>
  </div>
</template>
<script>
import { db } from '@/plugins/firebase';
import { callable } from '@/plugins/firebase';

export default {
  name: 'ObjectVersions',
  props: {
    objectId: {
      type: String,
      required: true,
    },
    generationId: {
      type: String
    },
  },
  data() {
    return {
      generations: [],
      reverting: false,
    }
  },
  methods: {
    revert(generationId) {
      this.reverting = true;
      callable('generations-makeActive', {
        organizationId: this.$store.state.organization.id,
        objectId: this.objectId,
        generationId
      })
      .then(() => {
        if(this.generationId !== generationId) this.$router.push(`/library/${this.objectId}?generation=${generationId}`)
      })
      .finally(() => { this.reverting = false; })
    },
    getVersions() {
      this.$store.commit('core/REMOVE_LISTENER', 'versionListener');
      if(this.objectId) {
        const listener = db.collection(`organizations/${this.$store.state.organization.id}/objects/${this.objectId}/generations`)
          .orderBy('created.time', 'desc')
          .onSnapshot(querySnap => {
            querySnap.docChanges().forEach((change) => {
              const { type, doc, newIndex } = change;
              const index = this.generations.findIndex(({id}) => id === doc.id);
              if(index > -1) this.generations.splice(index, 1);
              if(type !== 'removed') {
                this.generations.splice(newIndex, 0, { id: doc.id, ...doc.data() });
              }
            });
          });
        this.$store.commit('core/ADD_LISTENER', { id: 'versionListener', listener });
      }
    }
  },
  beforeDestroy() {
    this.$store.commit('core/REMOVE_LISTENER', 'versionListener');
  },
  watch: {
    generationId: {
      immediate: true,
      handler (generationId) {
        if (generationId) this.getVersions(generationId)
      }
    }
  }
}
</script>