<template>
  <div class="py-8 px-28">
    <div class="mb-12">
      <h1 class="border-b border-primary pb-4 mb-8">Account settings</h1>
      <div class="rounded-lg border border-primary p-8">
        <div class="flex flex-table">
          <div class="mr-24 font-bold">
            <div class="h-6 flex items-center">
              <div v-text="'Email'" />
            </div>
            <div class="h-6 flex items-center">
              <div v-text="'Name'" />
            </div>
            <div class="h-6 flex items-center">
              <div v-text="'Role'" />
              <Info class="ml-2" :size=4>
                Permission level, can be changed by admins under Organization settings
              </Info>
            </div>
            <div class="h-6 flex items-center">
              <div v-text="'Resource'" />
              <Info class="ml-2" :size=4>
                Access to selected resources, can be changed by admins under Organization settings
              </Info>
            </div>
          </div>
          <div>
            <div class="h-6 flex items-center">
              <div v-text="$store.state.user.email" />
            </div>
            <div class="h-6 flex items-center">
              <EditableField
                :key="$store.state.user.name"
                :currentValue="$store.state.user.name"
                :validations="nameValidations"
                :validationErrors="nameValidationErrors"
                :save="($event) => saveName($event)"
              />
            </div>
            <div class="h-6 flex items-center">
              {{ $store.getters['user/getRole'] | capitalize }}
            </div>
            <div class="h-6 flex items-center">
              {{ $store.getters['user/getResource'] | capitalize }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EditableField from '@/components/base/EditableField';
import { required } from 'vuelidate/lib/validators';
import Info from '@/components/base/Info.vue';
import { firebase, db } from '@/plugins/firebase';

export default {
  name: 'Account',
  components: {
    EditableField,
    Info
  },
  data() {
    return {
      nameValidations: { required },
      nameValidationErrors: {
        required: 'Name is required'
      },
    }
  },
  methods: {
    saveName(name) {
      const batch = db.batch()
      batch.set(db.doc(`users/${this.$store.state.user.uid}/metaData/${this.$store.state.organization.id}`), { name }, {merge: true})
      batch.set(db.collection(`organizations/${this.$store.state.organization.id}/log`).doc(), {
        created: {
          time: firebase.firestore.Timestamp.now(),
          uid: this.$store.state.user.uid,
        },
        type: 'user',
        action: 'changeName',
        text: `User <b>${this.$store.state.user.email}</b> changed name to <b>${name}</b>`,
        user: {
          uid: this.$store.state.user.uid,
        },
      })
      return batch.commit()
    },
  }
}
</script>
