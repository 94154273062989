<template>
  <table>
    <thead>
      <tr>
        <td
          v-for="header in headers"
          :key="header.value"
        >
          <div class="flex">
            <div v-text="header.text" />
            <Info v-if="!!header.info" class="ml-2" :placement="header.placement" :size=4>
              <div v-html="header.info" />
            </Info>
          </div>
        </td>
      </tr>
    </thead>
    <tbody v-if="loading">
      <tr>
        <td :colspan="headers.length">
          <div class="flex items-center justify-center">
            <div class="flex">
              <svg class="animate-spin h-6 w-6 mr-2" viewBox="0 0 24 24">
                <path class="" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
    <tbody v-else-if="!claims.length">
      <tr>
        <td :colspan="headers.length" class="text-center">
          No users
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr
        v-for="claim in claims"
        :key="claim.id"
      >
        <td>
          {{ claim.email }}
        </td>
        <td>
          <UserChangeRole
            @refresh-claims="$emit('refresh-claims')"
            :claim="claim"
          />
        </td>
        <td>
          <UserChangeResource
            @refresh-claims="$emit('refresh-claims')"
            :claim="claim"
          />
        </td>
        <td align="right">
          <UserRemove
            @refresh-claims="$emit('refresh-claims')"
            v-if="adminRights(claim)" :claim="claim"
          />
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import UserChangeRole from './UserChangeRole.vue';
import UserChangeResource from './UserChangeResource.vue';
import UserRemove from './UserRemove.vue';
import Info from '@/components/base/Info.vue';

export default {
  name: 'UsersList',
  components: {
    UserChangeRole,
    UserChangeResource,
    UserRemove,
    Info,
  },
  props: {
    claims: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean
    }
  },
  data() {
    return {
      headers: [
        { text: 'Email', value: 'email'},
        { text: 'Role', value: 'role', info: 'Users: basic print access<br>Admin: modify library, printer settings, user management' },
        { text: 'Department', value: 'department', info: 'All or selected departments', placement: 'bottom-start' },
        { text: '', value: 'remove' }
      ],
    }
  },
  methods: {
    adminRights(claim) {
      if(claim.role === 'owner') return false;
      return this.$store.getters['user/isAdmin'](claim.resource);
    },
  },
}
</script>