<template>
  <div class="flex items-center relative">
    <div class="absolute left-0 pl-2">
      <svg v-if="loading" class="animate-spin" :class="iconClass" viewBox="0 0 24 24">
        <path d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
      <svg v-else :class="iconClass" viewBox="0 0 24 24">
        <path d="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z" />
      </svg>
    </div>
    <form @submit.prevent="submit">
      <input
        class="cursor-pointer outline-none bg-transparent border border-transparent rounded-md"
        :type="maskValue ? 'password' : 'text'"
        v-model.trim="$v.newValue.$model"
        :class="[
          $v.newValue.$dirty && $v.newValue.$invalid ? 'border-error' : 'active:border-primary focus:border-primary hover:border-primary',
          inputClass
        ]"
        v-autowidth="{comfortZone: 20, maxWidth: maxWidth ? `${maxWidth}px` : 'none'}"
        :disabled="loading"
        :placeholder="displayValue"
        @change="submit"
      >
    </form>
    <div
      v-if="errors.length"
      class="ml-1 text-error text-xs"
      v-text="errors.join(', ')"
    />
  </div>
</template>
<script>

export default {
  name: 'EditableField',
  directives: {
  },
  props: {
    currentValue: {
      type: String,
      default: null,
    },
    validations: {
      type: Object,
      required: false,
      default: () => null,
    },
    validationErrors: {
      type: Object,
      required: false,
      default: () => null,
    },
    save: {
      type: Function,
      required: true
    },
    maskValue: {
      type: String,
      default: null,
    },
    emptyValue: {
      type: String,
      default: 'Unnamed',
    },
    iconClass: {
      type: String,
      default: 'h-4 w-4 mr-2'
    },
    inputClass: {
      type: String,
      default: 'pl-8 pr-2 py-1'
    },
    maxWidth: {
      type: Number,
      default: null,
    },
  },
  validations() {
    if(this.validations) return {
      newValue: this.validations,
    }
  },
  data() {
    return {
      loading: false,
      newValue: this.maskValue ? null : this.currentValue,
    }
  },
  computed: {
    displayValue() {
      if(this.maskValue) return this.maskValue;
      if([null,''].includes(this.newValue)) return this.emptyValue;
      return this.newValue;
    },
    errors() {
      if(!this.$v.newValue.$dirty) return [];
      return Object.keys(this.validations).reduce((c, validation) => {
        if(!this.$v.newValue[validation] && this.validationErrors && this.validationErrors[validation]) return [...c, this.validationErrors[validation]];
        return c;
      }, []);
    }
  },
  methods: {
    submit() {
      if(!this.$v.newValue.$invalid && !this.loading) {
        this.loading = true
        this.save(this.newValue).finally(() => { this.loading = false; })
      }
    }
  },
  watch: {
    currentValue(newValue) {
      if(!this.maskValue) this.newValue = newValue;
    }
  }
}
</script>