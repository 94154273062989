var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
    content: _vm.tooltip,
    placement: 'bottom',
    offset: 4,
    disabled: !_vm.tooltip
  }),expression:"{\n    content: tooltip,\n    placement: 'bottom',\n    offset: 4,\n    disabled: !tooltip\n  }"}]},[_c('button',{staticClass:"relative flex justify-center items-center focus:outline-none",class:[
      !_vm.disabled && _vm.type !== 'blank' ? _vm.bgColor[_vm.color] : '',
      _vm.disabled && _vm.type !== 'blank' ? _vm.disabledBg[_vm.color] : '',
      _vm.borderColor[_vm.color],
      _vm.type !== 'blank' ? _vm.textColorWithBg[_vm.color] : _vm.textColorWoBg[_vm.color],
      _vm.types[_vm.type],
      _vm.loading || _vm.disabled ? 'cursor-not-allowed' : '',
      !_vm.loading && !_vm.disabled ? _vm.hover[_vm.type] : null
    ],attrs:{"disabled":_vm.loading || _vm.disabled},on:{"click":function($event){return _vm.$emit('click')}}},[(_vm.loading)?_c('div',{staticClass:"absolute inset-0 flex items-center justify-center",class:[
        !_vm.disabled && _vm.type !== 'blank' ? _vm.bgColor[_vm.color] : '',
        _vm.types[_vm.type] ]},[_c('svg',{staticClass:"animate-spin h-5 w-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24"}},[_c('circle',{staticClass:"opacity-25",attrs:{"cx":"12","cy":"12","r":"10","stroke":"currentColor","stroke-width":"4"}}),_c('path',{staticClass:"opacity-75",attrs:{"fill":"currentColor","d":"M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"}})])]):_vm._e(),_c('div',{staticClass:"flex",class:{'invisible': _vm.loading}},[_vm._t("default")],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }