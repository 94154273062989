import Vue from "vue";
import Capitalize from './filters/capitalize';
import FormatTimeDistance from './filters/formatTimeDistance';
import FormatTimestamp from './filters/formatTimestamp';
import FormatFileSize from './filters/formatFileSize';
import FormatDuration from './filters/formatDuration';
import FormatCurrency from './filters/formatCurrency';
import VueInputAutowidth from 'vue-input-autowidth';
import Vuelidate from 'vuelidate';
import PortalVue from 'portal-vue';
import VTooltip from 'v-tooltip';
import VueApollo from 'vue-apollo'
import { notifications } from './services/notifications';
import PageLoading from '@/components/other/PageLoading.vue';
import App from "./App.vue";
import store from "./store";
import router from "./router";
import { auth } from './plugins/firebase';
import { stateChanges, signOut } from './services/user';
// import { watch, unWatch } from './services/clientUpdate';
import "./main.css";

Vue.filter('capitalize', Capitalize);
Vue.filter('formatTimeDistance', FormatTimeDistance);
Vue.filter('formatTimestamp', FormatTimestamp);
Vue.filter('formatFileSize', FormatFileSize);
Vue.filter('formatDuration', FormatDuration);
Vue.filter('formatCurrency', FormatCurrency);
Vue.use(VueInputAutowidth);
Vue.use(Vuelidate);
Vue.use(PortalVue);
Vue.use(VTooltip);
Vue.use(VueApollo)
Vue.prototype.$notifications = notifications;

Vue.component('page-loading', PageLoading);

const apolloProvider = new VueApollo({
  defaultOptions: {
    $query: {
      fetchPolicy: 'network-only',
    },
  },
  errorHandler (error) {
    console.error(error)
  },

})

Vue.config.productionTip = false;
let app
auth.onAuthStateChanged(async (user) => {
  // User service
  await stateChanges(user);
  // Render app
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App),
      apolloProvider,
      mounted() {
        // watch();
      },
      async beforeDestroy() {
        // unWatch();
        await signOut();
      },
    }).$mount('#app')
  }
});
