<template>
  <div class="navbar bg-navbar flex items-center justify-between">
    <div class="flex items-center">
      <img class="h-6 mr-12" src="/images/rdlogo_white.png" />
      <router-link
        v-for="link in mainLinks"
        :key="link.to"
        :to="link.to"
        class="px-3 py-1 flex items-center navbar-link rounded-lg relative justify-center"
        :title="link.text"
      >
        <svg viewBox="0 0 24 24">
          <path :d="link.svgPath" />
        </svg>
      </router-link>
    </div>
    <div class="flex">
      <router-link
        v-if="isAdmin"
        to="/slicer-installation"
        class="px-3 py-1 flex items-center navbar-link rounded-lg relative justify-center text-white"
        title="Slicer installation"
      >
      <svg viewBox="0 0 24 24">
          <path d="M17 14L22 9L20.6 7.6L18 10.2V3H16V10.2L13.4 7.6L12 9L17 14M23 14V16C23 17.1 22.1 18 21 18H14V20H16V22H8V20H10V18H3C1.9 18 1 17.1 1 16V4C1 2.9 1.9 2 3 2H12V4H3V16H21V14H23Z" />
      </svg>
      </router-link>
      <Dropdown origin="origin-top-right">
        <template #activator>
          <button class="px-3 py-1 flex items-center navbar-link rounded-lg justify-center">
            <svg viewBox="0 0 24 24">
              <path d="M19.79,15.41C20.74,13.24 20.74,10.75 19.79,8.59L17.05,9.83C17.65,11.21 17.65,12.78 17.06,14.17L19.79,15.41M15.42,4.21C13.25,3.26 10.76,3.26 8.59,4.21L9.83,6.94C11.22,6.35 12.79,6.35 14.18,6.95L15.42,4.21M4.21,8.58C3.26,10.76 3.26,13.24 4.21,15.42L6.95,14.17C6.35,12.79 6.35,11.21 6.95,9.82L4.21,8.58M8.59,19.79C10.76,20.74 13.25,20.74 15.42,19.78L14.18,17.05C12.8,17.65 11.22,17.65 9.84,17.06L8.59,19.79M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,8A4,4 0 0,0 8,12A4,4 0 0,0 12,16A4,4 0 0,0 16,12A4,4 0 0,0 12,8Z" />
            </svg>
          </button>
        </template>
        <template>
          <Contact class="text-primary" style="width:300px" />
        </template>
      </Dropdown>
      <Dropdown origin="origin-top-right">
        <template #activator>
          <button class="px-3 py-1 flex items-center navbar-link rounded-lg justify-center">
            <svg viewBox="0 0 24 24">
              <path d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" />
            </svg>
          </button>
        </template>
        <template>
          <div class="text-primary" style="width: 300px">
            <div class="mt-2 mb-4 px-4">
              <div style="font-size: 9px;" class="text-right text-gray-500">{{ buildTime }}</div>
              <div class="text-lg font-medium" v-text="$store.state.user.email" />
              <div class="flex justify-between">
                <div class="truncate" v-text="$store.state.user.name" />
                <div>{{ $store.getters['user/getRole'] | capitalize }}</div>
              </div>
            </div>
            <div class="my-2 border-b border-primary" />
            <ul>
              <li
                v-for="link in filteredSubMenuLinks"
                :key="link.to"
              >
                <router-link :to="link.to" class="block px-4 py-2 hover:bg-light font-bold" v-text="link.text" />
              </li>
              <li>
                <a href="#" class="block px-4 py-2 hover:bg-light font-bold" v-text="'Sign out'" v-on:click.prevent="signOut" />
              </li>
            </ul>
          </div>
        </template>
      </Dropdown>
    </div>
  </div>
</template>
<script>
import Contact from '@/components/other/Contact.vue';
import Dropdown from '@/components/base/Dropdown.vue';
import { signOut } from '@/services/user';

export default {
  name: 'Toolbar',
  components: {
    Dropdown,
    Contact
  },
  data() {
    return {
      buildTime: null,
      mainLinks: [
        { text: 'Library', to: '/library', svgPath: 'M12,8A3,3 0 0,0 15,5A3,3 0 0,0 12,2A3,3 0 0,0 9,5A3,3 0 0,0 12,8M12,11.54C9.64,9.35 6.5,8 3,8V19C6.5,19 9.64,20.35 12,22.54C14.36,20.35 17.5,19 21,19V8C17.5,8 14.36,9.35 12,11.54Z' },
        { text: 'Printers', to: '/printers', svgPath: 'M19,6A1,1 0 0,0 20,5A1,1 0 0,0 19,4A1,1 0 0,0 18,5A1,1 0 0,0 19,6M19,2A3,3 0 0,1 22,5V11H18V7H6V11H2V5A3,3 0 0,1 5,2H19M18,18.25C18,18.63 17.79,18.96 17.47,19.13L12.57,21.82C12.4,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L6.53,19.13C6.21,18.96 6,18.63 6,18.25V13C6,12.62 6.21,12.29 6.53,12.12L11.43,9.68C11.59,9.56 11.79,9.5 12,9.5C12.21,9.5 12.4,9.56 12.57,9.68L17.47,12.12C17.79,12.29 18,12.62 18,13V18.25M12,11.65L9.04,13L12,14.6L14.96,13L12,11.65M8,17.66L11,19.29V16.33L8,14.71V17.66M16,17.66V14.71L13,16.33V19.29L16,17.66Z' },
        { text: 'My log', to: '/history', svgPath: 'M9,5V9H21V5M9,19H21V15H9M9,14H21V10H9M4,9H8V5H4M4,19H8V15H4M4,14H8V10H4V14Z' },
      ],
      subMenuLinks: [
        { text: 'Account settings', to: '/account', admin: false },
        { text: 'Organization settings', to: '/settings', admin: true },
        { text: 'Users', to: '/users', admin: true },
        { text: 'Organization logs', to: '/logs', admin: true },
      ]
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters['user/isAdmin']();
    },
    filteredSecondaryLinks() {
      if(this.isAdmin) return this.secondaryLinks;
      return this.secondaryLinks.filter(({ admin }) => !admin)
    },
    filteredSubMenuLinks() {
      if(this.isAdmin) return this.subMenuLinks;
      return this.subMenuLinks.filter(({ admin }) => !admin)
    },
  },
  methods: {
    signOut() {
      signOut(true).then(() => this.$router.push('/login'));
    }
  },
  mounted() {
    this.buildTime = `Build: ${document.documentElement.dataset.buildTime}`
  }
}
</script>
<style scoped>
.navbar {
  @apply text-white;
}
.navbar svg {
  @apply text-white;
  @apply fill-current;
  @apply w-8;
  @apply h-8;
}
.navbar-link.router-link-active {
  @apply bg-navbar-hover;
  @apply shadow-inner;
}
.navbar-link:hover,
.navbar-link.router-link-active {
  @apply bg-navbar-hover;
}
</style>