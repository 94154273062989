<template>
  <div class="w-full pt-24 flex items-center justify-center">
    <div class="flex">
      <svg class="animate-spin h-16 w-16 mr-2" viewBox="0 0 24 24">
        <path class="" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PageLoading',
}
</script>