// Logs
const logTypes = {
  object: {
    icon: 'M21,16.5C21,16.88 20.79,17.21 20.47,17.38L12.57,21.82C12.41,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L3.53,17.38C3.21,17.21 3,16.88 3,16.5V7.5C3,7.12 3.21,6.79 3.53,6.62L11.43,2.18C11.59,2.06 11.79,2 12,2C12.21,2 12.41,2.06 12.57,2.18L20.47,6.62C20.79,6.79 21,7.12 21,7.5V16.5M12,4.15L6.04,7.5L12,10.85L17.96,7.5L12,4.15M5,15.91L11,19.29V12.58L5,9.21V15.91M19,15.91V9.21L13,12.58V19.29L19,15.91Z',
    text: 'Object',
  },
  printer: {
    icon: 'M19,6A1,1 0 0,0 20,5A1,1 0 0,0 19,4A1,1 0 0,0 18,5A1,1 0 0,0 19,6M19,2A3,3 0 0,1 22,5V11H18V7H6V11H2V5A3,3 0 0,1 5,2H19M18,18.25C18,18.63 17.79,18.96 17.47,19.13L12.57,21.82C12.4,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L6.53,19.13C6.21,18.96 6,18.63 6,18.25V13C6,12.62 6.21,12.29 6.53,12.12L11.43,9.68C11.59,9.56 11.79,9.5 12,9.5C12.21,9.5 12.4,9.56 12.57,9.68L17.47,12.12C17.79,12.29 18,12.62 18,13V18.25M12,11.65L9.04,13L12,14.6L14.96,13L12,11.65M8,17.66L11,19.29V16.33L8,14.71V17.66M16,17.66V14.71L13,16.33V19.29L16,17.66Z',
    text: 'Printer'
  },
  user: {
    icon: 'M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z',
    text: 'User'
  },
  organization: {
    icon: 'M18,15H16V17H18M18,11H16V13H18M20,19H12V17H14V15H12V13H14V11H12V9H20M10,7H8V5H10M10,11H8V9H10M10,15H8V13H10M10,19H8V17H10M6,7H4V5H6M6,11H4V9H6M6,15H4V13H6M6,19H4V17H6M12,7V3H2V21H22V7H12Z',
    text: 'Organization'
  },
  department: {
    icon: 'M18,15H16V17H18M18,11H16V13H18M20,19H12V17H14V15H12V13H14V11H12V9H20M10,7H8V5H10M10,11H8V9H10M10,15H8V13H10M10,19H8V17H10M6,7H4V5H6M6,11H4V9H6M6,15H4V13H6M6,19H4V17H6M12,7V3H2V21H22V7H12Z',
    text: 'Department'
  },
  email: {
    icon: 'M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z',
    text: 'Email'
  },
  printJob: {
    icon: 'M7,2H17V8H19V13H16.5L13,17H11L7.5,13H5V8H7V2M10,22H2V20H10A1,1 0 0,0 11,19V18H13V19A3,3 0 0,1 10,22Z',
    text: 'Print job'
  },
}

const formatLog = (doc) => {
  let reference = '';
  if(doc.data().printer) {
    reference += `/printers/${doc.data().printer.id}`;
  } else if(doc.data().object) {
    reference += `/library/${doc.data().object.id}`;
  } else if(doc.data().generation) {
    reference += `?generation=${doc.data().generation.id}`;
  } else if(doc.data().user) {
    reference += '/account';
  } else if(doc.data().type === 'organization') {
    reference += '/settings';
  }
  return {
    id: doc.id,
    created: doc.data().created,
    type: logTypes[doc.data().type],
    text: doc.data().text,
    reference: reference.length ? reference : null
  };
}

// Objects
const objectStatuses = [
  { text: 'Needs review', value: 'needsReview', color: 'text-warning', path: 'M13,8A4,4 0 0,1 9,12A4,4 0 0,1 5,8A4,4 0 0,1 9,4A4,4 0 0,1 13,8M17,18V20H1V18C1,15.79 4.58,14 9,14C13.42,14 17,15.79 17,18M20.5,14.5V16H19V14.5H20.5M18.5,9.5H17V9A3,3 0 0,1 20,6A3,3 0 0,1 23,9C23,9.97 22.5,10.88 21.71,11.41L21.41,11.6C20.84,12 20.5,12.61 20.5,13.3V13.5H19V13.3C19,12.11 19.6,11 20.59,10.35L20.88,10.16C21.27,9.9 21.5,9.47 21.5,9A1.5,1.5 0 0,0 20,7.5A1.5,1.5 0 0,0 18.5,9V9.5Z' },
  { text: 'Reviewed', value: 'reviewed', color: 'text-info', path: 'M21.1,12.5L22.5,13.91L15.97,20.5L12.5,17L13.9,15.59L15.97,17.67L21.1,12.5M10,17L13,20H3V18C3,15.79 6.58,14 11,14L12.89,14.11L10,17M11,4A4,4 0 0,1 15,8A4,4 0 0,1 11,12A4,4 0 0,1 7,8A4,4 0 0,1 11,4Z' },
  { text: 'Published', value: 'published', color: 'text-success', path: 'M10,17L6,13L7.41,11.59L10,14.17L16.59,7.58L18,9M12,1L3,5V11C3,16.55 6.84,21.74 12,23C17.16,21.74 21,16.55 21,11V5L12,1Z' },
  { text: 'Deleted', value: 'deleted', color: 'text-error', path: 'M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z' },
];

const objectStatus = (object, generation = null) => {
  if (object.deleted) return objectStatuses[3]
  if (!generation) return objectStatuses[0]
  if (generation.status && generation.status.published) return objectStatuses[2]
  if (generation.status && generation.status.reviewed) return objectStatuses[1]
  return objectStatuses[0]
}

// Printers
const printerStatuses = {
  UNKNOWN: { text: 'Unknown', chip: 'warning', color: 'text-warning' },
  UNAVAILABLE: { text: 'Unavailable', chip: 'warning', color: 'text-warning' },
  IDLE: { text: 'Idle', chip: 'success', color: 'text-success' },
  BUSY: { text: 'Busy', chip: 'warning', color: 'text-warning' },
  HOMING: { text: 'Homing', chip: 'warning', color: 'text-warning' },
  PRINTING: { text: 'Printing', chip: 'info', color: 'text-info' },
  PAUSED: { text: 'Paused', chip: 'warning', color: 'text-warning' },
  PAUSING: { text: 'Pausing', chip: 'warning', color: 'text-warning' },
  FAILED: { text: 'Failed', chip: 'error', color: 'text-error' },
}

export {
  formatLog,
  objectStatuses,
  objectStatus,
  printerStatuses
}