<template>
  <div class="py-8 px-28">
    <div class="mb-12">
      <h1 class="border-b border-primary pb-4 mb-8">Organization settings</h1>
      <div class="rounded-lg border border-primary p-8">
        <Settings />
      </div>
    </div>
    <div class="mb-12">
      <h2>Departments</h2>
      <div class="border-b border-primary pb-3 mb-6">Manage departments</div>
      <div class="px-12">
        <Departments />
      </div>
    </div>
  </div>
</template>
<script>
import Settings from './Settings.vue';
import Departments from './Departments.vue';

export default {
  name: 'Organization',
  components: {
    Settings,
    Departments
  },
}
</script>