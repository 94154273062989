import { SET_DATA, ADD_TO_LIST, REMOVE_FROM_LIST, SET_LIST, CLEAR_LIST } from './shared';

export default {
  namespaced: true,
  state: {
    id: null,
    name: null,
    allowedDomains: [],
    departments: [],
    users: [],
    printers: [],
    categories: [],
    loading: true,
  },
  mutations: {
    SET_DATA,
    ADD_TO_LIST,
    REMOVE_FROM_LIST,
    SET_LIST,
    CLEAR_LIST,
  },
  actions: {
  },
  getters: {
    getDepartments: state => state.departments,
    getDepartment: state => id => state.departments.find(i => i.id === id),
    getUserName: (state, _getters, rootState) => uid => {
      if(uid === rootState.user.uid) return 'me';
      if(uid === 'system') return 'system';
      const user = state.users.find(({id}) => id === uid)
      if (user.name && user.name !== 'No name user') return user.name
      if (user.email) return user.email
      return 'Unnamed user';
    },
    getPrinters: (state, getters) => {
      const printers = state.printers
        .filter(({ uuid }) => !!uuid)
        .map((printer) => ({
        ...printer,
        department: getters.getDepartment(printer.departmentId),
        publicUrl: `${printer.uuid}.balena-devices.com`
      }))
      printers.sort(({ id: A }, { id: B }) => A.localeCompare(B))
      return printers
    },
    printersInDepartment: (_state, getters) => departmentId => getters.getPrinters.filter(i => i.departmentId === departmentId),
    getPrinter: (_state, getters) => id => getters.getPrinters.find(i => i.id === id),
    categories: state => {
      const categories = [...state.categories];
      categories.sort((a, b) => {
        if (a === '') return -1;
        if (b === '') return 1;
        return a.localeCompare(b);
      });
      return categories;
    },
  }
};
