import { ADD_TO_LIST, REMOVE_FROM_LIST, SET_LIST, CLEAR_LIST } from './shared';

const savedState = {
  theme: 'default',
  activityPanel: false,
}

if(localStorage.getItem('core/state')) {
  Object.assign(savedState, JSON.parse(localStorage.getItem('core/state')));
}

export default {
  namespaced: true,
  state: {
    ...savedState,
    updates: [],
    materials: [],
    listeners: {},
  },
  mutations: {
    ADD_TO_LIST,
    REMOVE_FROM_LIST,
    SET_LIST,
    CLEAR_LIST,
    SET_ACTIVITY_PANEL(state, show) {
      state.activityPanel = show;
      localStorage.setItem('core/state', JSON.stringify({
        theme: state.theme,
        activityPanel: state.activityPanel
      }))
    },
    ADD_LISTENER(state, { id, listener }) {
      state.listeners[id] = listener;
    },
    REMOVE_LISTENER(state, id) {
      if(state.listeners[id]) state.listeners[id]();
    },
    CLEAR_LISTENERS(state) {
      Object.keys(state.listeners).forEach((key) => {
        if(state.listeners[key]) state.listeners[key]();
      });
    },
  },
  getters: {
    findMaterialById: state => (id) => {
      const material = state.materials.find(i => i.id === id)
      if (!material) return null
      return material.name
    }
  }
};
