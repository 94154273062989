<template>
  <div>
    <div v-if="!generation" class="px-4">
      No version, upload STL or gCode to create a version.
    </div>
    <div v-else>
      <div class="px-4 font-medium uppercase tracking-wide border-b mb-2 pb-1">Description</div>
      <div class="mb-8 px-4">
        <div class="relative">
          <textarea
            rows=2
            name="message"
            class="p-1 w-full bg-transparent border border-primary"
            type="text"
            :disabled="!isAdmin || changingDescription"
            :value="generation.description"
            @change="newDescription($event.target.value)"
          />
          <div v-if="changingDescription" class="absolute inset-0 flex justify-center items-center">
            <svg class="animate-spin text-primary h-5 w-5" viewBox="0 0 24 24">
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        </div>
      </div>
      <div class="px-4 font-medium uppercase tracking-wide border-b mb-2 pb-1">Calculations</div>
      <div class="mb-8 px-4 flex">
        <div class="space-y-2 mr-12 list-items">
          <div>
            <div>Dimensions</div>
            <div v-if="calculations.dimensions">{{ `${calculations.dimensions.X || ''}mm x ${calculations.dimensions.Y || ''}mm x ${calculations.dimensions.Z || ''}mm` }}</div>
          </div>
          <div>
            <div>Print time</div>
            <div v-if="calculations.estPrintTime">{{ calculations.estPrintTime | formatDuration }}</div>
          </div>
          <!-- <div>
            <div>Material cost</div>
            <div v-if="calculations.materialCost">{{ calculations.materialCost | formatCurrency('SEK') }}</div>
          </div> -->
        </div>
        <div class="space-y-2 list-items">
          <div>
            <div>Object mass</div>
            <div v-if="calculations.mass">{{ calculations.mass }} g</div>
            <div v-else>N/A</div>
          </div>
          <!-- <div>
            <div>Plastic volume</div>
            <div v-if="calculations.volumePlastics">{{ calculations.volumePlastics }} cm<sup>3</sup></div>
            <div v-else>N/A</div>
          </div> -->
        </div>
      </div>
      <div v-if="isAdmin">
        <div class="px-4 font-medium uppercase tracking-wide border-b mb-2 pb-1">Catalog</div>
        <div class="flex justify-between mb-8 px-4">
          <div class="list-items space-y-2">
            <div>
              <div>Review status</div>
              <div class="flex items-centers mb-2">
                <svg
                  class="h-5 w-5 mr-2 fill-current"
                  :class="displayStatus.color"
                  viewBox="0 0 24 24"
                >
                  <path :d="displayStatus.path" />
                </svg>
                {{ displayStatus.text }}
              </div>
            </div>
            <div>
              <div>Change status</div>
              <div v-if="statusChanging">
                <svg class="animate-spin w-5 h-5" viewBox="0 0 24 24">
                  <path d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
              <div
                v-else-if="generation.status.published"
                class="flex items-center hover:cursor-pointer text-xs border-primary border rounded-md p-1 hover:bg-white"
                @click="changeStatus({ published: false })"
              >
                <svg class="w-4 h-4 mr-1 fill-current text-error" viewBox="0 0 24 24">
                  <path d="M10,17L6,13L7.41,11.59L10,14.17L16.59,7.58L18,9M12,1L3,5V11C3,16.55 6.84,21.74 12,23C17.16,21.74 21,16.55 21,11V5L12,1Z"></path>
                </svg>
                Unpublish
              </div>
              <div
                v-else-if="!generation.status.reviewed"
                class="flex items-center hover:cursor-pointer text-xs border-primary border rounded-md p-1 hover:bg-white"
                @click="changeStatus({ reviewed: true })"
              >
                <svg class="w-4 h-4 mr-1 fill-current text-info" viewBox="0 0 24 24">
                  <path d="M21,5L9,17L3.5,11.5L4.91,10.09L9,14.17L19.59,3.59L21,5M3,21V19H21V21H3Z"></path>
                </svg>
                Mark reviewed
              </div>
              <div v-else class="flex space-x-4">
                <div
                  class="flex items-center hover:cursor-pointer text-xs border-primary border rounded-md p-1 hover:bg-white"
                  @click="changeStatus({ published: true })"
                >
                  <svg class="w-4 h-4 mr-1 fill-current text-success" viewBox="0 0 24 24">
                    <path d="M10,17L6,13L7.41,11.59L10,14.17L16.59,7.58L18,9M12,1L3,5V11C3,16.55 6.84,21.74 12,23C17.16,21.74 21,16.55 21,11V5L12,1Z"></path>
                  </svg>
                  Publish
                </div>
                <div
                  class="flex items-center hover:cursor-pointer text-xs border-primary border rounded-md p-1 hover:bg-white"
                  @click="changeStatus({ reviewed: false })"
                >
                  <svg class="w-4 h-4 mr-1 fill-current text-warning" viewBox="0 0 24 24">
                    <path d="M13,8A4,4 0 0,1 9,12A4,4 0 0,1 5,8A4,4 0 0,1 9,4A4,4 0 0,1 13,8M17,18V20H1V18C1,15.79 4.58,14 9,14C13.42,14 17,15.79 17,18M20.5,14.5V16H19V14.5H20.5M18.5,9.5H17V9A3,3 0 0,1 20,6A3,3 0 0,1 23,9C23,9.97 22.5,10.88 21.71,11.41L21.41,11.6C20.84,12 20.5,12.61 20.5,13.3V13.5H19V13.3C19,12.11 19.6,11 20.59,10.35L20.88,10.16C21.27,9.9 21.5,9.47 21.5,9A1.5,1.5 0 0,0 20,7.5A1.5,1.5 0 0,0 18.5,9V9.5Z"></path>
                  </svg>
                  Needs review
                </div>
              </div>
            </div>
          </div>
          <div class="list-items space-y-2">
            <div>
              <div>Category</div>
              <div class="flex items-center">
                <ChangeCategory
                  :category="object.category"
                  :objectId="object.id"
                >
                  <template #activator>
                    <svg class="h-5 w-5 mr-2 fill-current text-info hover:cursor-pointer" viewBox="0 0 24 24">
                      <path d="M14,18V15H10V11H14V8L19,13M20,6H12L10,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V8C22,6.89 21.1,6 20,6Z" />
                    </svg>
                  </template>
                </ChangeCategory>
                <div>{{ object.category }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="px-4 font-medium uppercase tracking-wide border-b mb-2 pb-1">Files</div>
        <div class="list-items mb-8 px-4 flex justify-between">
          <div>
            <div>Source</div>
            <div v-if="generation.source === 'web'" v-text="'Web'" />
            <div v-else-if="generation.source === 'cura'" v-text="'Cura'" />
            <div v-else v-text="'Unknown'" />
          </div>
          <div>
            <div>STL file</div>
            <div v-if="generation.multipliers[1] && generation.multipliers[1].stl.exists" class="flex items-center">
              Yes
              <svg
                @click="download('stl')"
                class="h-5 w-5 ml-1 hover:cursor-pointer"
                viewBox="0 0 24 24"
              >
                <path d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" />
              </svg>
            </div>
            <svg v-else-if="generation.source === 'cura'" class="animate-spin h-4 w-4 ml-2 mt-1" viewBox="0 0 24 24">
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <div v-else v-text="'N/A'" />
          </div>
          <div>
            <div>gCode file</div>
            <div v-if="generation.multipliers[1] && generation.multipliers[1].gCode.exists" class="flex items-center">
              Yes
              <svg
                @click="download('gCode')"
                class="h-5 w-5 ml-1 hover:cursor-pointer"
                viewBox="0 0 24 24"
              >
                <path d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" />
              </svg>
            </div>
            <svg v-else class="animate-spin h-4 w-4 ml-2 mt-1" viewBox="0 0 24 24">
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        </div>
        <div class="px-4 font-medium uppercase tracking-wide border-b mb-2 pb-1">Optimizations</div>
        <div class="list-items mb-8 px-4 flex justify-between">
          <div>
            <div>Quantities</div>
            <div>{{ generation.availableMultipliers.join(' / ') }}</div>
          </div>
        </div>
        <div class="px-4 font-medium uppercase tracking-wide border-b mb-2 pb-1">Changes</div>
        <div class="list-items mb-2 px-4 flex justify-between">
          <div>
            <div>Created</div>
            <div class="flex items-center">
              <div>{{ object.created.time | formatTimestamp('d MMM yyyy HH:mm') }} by {{ $store.getters['organization/getUserName'](generation.created.uid) }}</div>
            </div>
          </div>
          <div>
            <div>Last updated</div>
            <div class="flex items-center">
              <div>{{ generation.updated.time | formatTimestamp('d MMM yyyy HH:mm') }} by {{ $store.getters['organization/getUserName'](generation.updated.uid) }}</div>
            </div>
          </div>
        </div>
        <div class="list-items mb-2 px-4 flex justify-between">
          <div>
            <div>Versions</div>
            <div class="flex items-center">
              <div>{{ object.versions }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { callable } from '@/plugins/firebase';
import { objectStatus } from '@/services/formatting'
import ChangeCategory from '@/components/objects/ChangeCategory.vue';

export default {
  name: 'ObjectDetails',
  components: {
    ChangeCategory,
  },
  props: {
    object: {
      type: Object,
      required: true,
    },
    generation: {
      type: Object,
      default: () => {}
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    readyToPrint: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      changingDescription: false,
      statusChanging: false
    }
  },
  computed: {
    displayStatus () {
      return objectStatus(this.object, this.generation)
    },
    multiplier() {
      if(!this.generation.multipliers || !this.generation.multipliers[1]) return null;
      return this.generation.multipliers[1]
    },
    calculations() {
      if(!this.multiplier || !this.multiplier.calculations) return {};
      return this.multiplier.calculations
    }
  },
  methods: {
    async download(type) {
      callable('multipliers-download', {
        organizationId: this.$store.state.organization.id,
        objectId: this.object.id,
        generationId: this.generation.id,
        multiplier: 1,
        type
      })
        .then(url => {
          var link = document.createElement('a');
          link.href = url;
          link.target = '_blank';
          document.body.appendChild(link);
          link.click();
        })
        .catch(err => {
          console.error(err)
        })
    },
    newDescription(description) {
      if(this.isAdmin) {
        this.changingDescription = true;
        callable('generations-changeMeta', {
          organizationId: this.$store.state.organization.id,
          objectId: this.object.id,
          generationId: this.generation.id,
          description,
        })
        .finally(() => { this.changingDescription = false; })
      }
    },
    changeStatus (change) {
      this.statusChanging = true;
      callable('generations-status', {
        organizationId: this.$store.state.organization.id,
        objectId: this.object.id,
        generationId: this.generation.id,
        ...change
      })
      .catch(err => {console.error(err)})
      .finally(() => { this.statusChanging = false; })
    }
  }
}
</script>
<style scoped>
.list-items > div > div:first-child {
  @apply text-muted;
}
.list-items > div > div:nth-child(2) {
  @apply font-medium;
}
</style>