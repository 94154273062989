<template>
  <div class="h-full">
    <div v-if="connecting" class="w-full h-full flex flex-col items-center justify-center text-muted text-xl">
      <svg class="animate-spin h-7 w-7 mb-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
      <div class="text-sm">Connecting to printer...</div>
    </div>
    <div v-else-if="!connecting && !connected" class="w-full h-full flex flex-col items-center justify-center text-error text-xl">
      <svg class="h-7 w-7 mb-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <path class="opacity-75" fill="currentColor" d="M4,1C2.89,1 2,1.89 2,3V7C2,8.11 2.89,9 4,9H1V11H13V9H10C11.11,9 12,8.11 12,7V3C12,1.89 11.11,1 10,1H4M4,3H10V7H4V3M14,13C12.89,13 12,13.89 12,15V19C12,20.11 12.89,21 14,21H11V23H23V21H20C21.11,21 22,20.11 22,19V15C22,13.89 21.11,13 20,13H14M3.88,13.46L2.46,14.88L4.59,17L2.46,19.12L3.88,20.54L6,18.41L8.12,20.54L9.54,19.12L7.41,17L9.54,14.88L8.12,13.46L6,15.59L3.88,13.46M14,15H20V19H14V15Z"></path>
      </svg>
      <div class="text-sm">Could not connect to printer</div>
      <div class="text-sm mb-2">It could be shutdown or not connected to the internet</div>
      <Button
        type="tight"
        @click="connect(printerId)"
      >
        Retry
      </Button>
    </div>
    <div v-else-if="connected">
      <printer-name
        v-if="printer"
        :printer="printer"
        :client="client"
      />
      <div class="px-6 pt-6">
        <div class="bg-red-200 p-2 rounded-md mb-6 flex items-center" v-if="errorMessage">
          <svg class="w-8 h-8 mr-2 text-red-400 fill-current" viewBox="0 0 24 24">
            <path fill="currentColor" d="M13 14H11V9H13M13 18H11V16H13M1 21H23L12 2L1 21Z" />
          </svg>
          {{ errorMessage }}
        </div>
        <div class="flex items-start justify-between gap-x-4">
          <div class="shadow flex-shrink-0" style="height: 335px;">
            <div class="px-6 py-2 bg-fade text-muted uppercase font-medium border-b border-primary" v-text="'Status'" />
            <div class="flex">
              <Status
                v-if="printer"
                class="border-r border-primary flex-shrink-0 h-full"
                style="width: 430px; height: 302px"
                :printer="printer"
                :client="client"
                :refresh="query"
                :showError="showError"
              />
              <meta-data
                v-if="printer"
                class="h-full"
                style="width: 200px"
                :printer="printer"
                :client="client"
                :showError="showError"
              />
            </div>
          </div>
          <Webcam
            v-if="connected"
            :publicUrl="publicUrl"
            :token="token"
            class="shadow"
            style="height: 335px; width: 300px"
          />
        </div>
      </div>
      <div class="p-6">
        <div class="shadow flex flex-col">
          <div class="px-6 py-2 bg-fade text-muted uppercase font-medium border-b border-primary" v-text="'Print queue'" />
          <div class="mx-6 text-sm my-2">
            <Queue
              v-if="printer"
              :printer="printer"
              :client="client"
              :refresh="query"
              :showError="showError"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Queue from '@/components/printer/Queue';
import Button from '@/components/base/Button';
import Webcam from '@/components/printer/Webcam';
import Status from '@/components/printer/Status';
import MetaData from '@/components/printer/MetaData';
import PrinterName from '@/components/printer/PrinterName';
import { PrinterAPIClient } from '@/libs/printer-client';
import gql from 'graphql-tag'

const query = `
Printer {
  printerMetaData {
    name,
    location
  },
  printerState {
    clearedBuildPlate
    materials {
      trayA {
        id
        sku
      },
      trayB {
        id
        sku
      }
    }
    printerStatus
    remotePrintingStatus
    lightsOn
  },
  queueMetaData {
    jobCount
    runCount
    printTime
  },
  queue {
    id
    objectId,
    objectName,
    objectThumbnail,
    generationId,
    quantity,
    estPrintTime,
    quantityCompleted,
    completed
    created {
      time
      uid
    }
    materials {
      trayA
      trayB
    }
    runs {
      id
      started
      ended
      lastStart
      runTime
      estPrintTime
      quantity
      gCodeDownloaded
      timeLeft
      progress
      status
    }
  },
  activeJob {
    id
    objectId,
    objectName,
    objectThumbnail,
    generationId,
    quantity
    estPrintTime,
    quantityCompleted
    completed
    created {
      time
      uid
    }
    materials {
      trayA
      trayB
    }
    runs {
      id
      started
      ended
      lastStart
      runTime
      estPrintTime
      quantity
      gCodeDownloaded
      timeLeft
      progress
      status
    }
  },
  activeRun {
    id
    started
    ended
    lastStart
    runTime
    estPrintTime
    quantity
    gCodeDownloaded
    timeLeft
    progress
    status
  }
}
`
export default {
  name: 'Printer',
  components: {
    Button,
    Webcam,
    Queue,
    MetaData,
    Status,
    PrinterName
  },
  props: {
    printerId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      connecting: true,
      connected: false,
      client: null,
      printer: null,
      queryTimeout: null,
      errorMessage: null
    }
  },
  computed: {
    token() {
      return this.$store.state.user.token
    },
    publicUrl() {
      if (!this.printerId) return null;
      return this.$store.getters['organization/getPrinter'](this.printerId).publicUrl
    },
    httpUri() {
      if (!this.publicUrl) return null;
      return `https://${this.publicUrl}/graphql`;
    },
    wsUri() {
      if (!this.publicUrl) return null;
      return `wss://${this.publicUrl}/graphql`;
    },
    refetchKey() {
      return `${this.publicUrl}${this.httpUri}${this.token}`
    }
  },
  methods: {
    async disconnect() {
      if (this.queryTimeout) clearTimeout(this.queryTimeout);
      if (this.client && this.client.disconnect) {
        await this.client.disconnect()
      }
      this.client = null;
      this.connected = false
      this.printer = null
    },
    async connect () {
      if (this.queryTimeout) clearTimeout(this.queryTimeout);
      if (!this.httpUri) return;
      this.connecting = true
      this.printer = null
      await this.disconnect()
      try {
        this.client = new PrinterAPIClient('web-ui', this.httpUri, null, this.token)
        await this.query()
      } finally {
        this.connecting = false;
      }
    },
    async query(connectCount = 1) {
      try {
        if (this.queryTimeout) clearTimeout(this.queryTimeout);
        if (!this.client) return;
        
        const { Printer } = await this.client.graphQL.query(gql`
          query PeriodicFetchQuery {
            ${query}
          }
        `)
        this.printer = Printer;
        this.connected = true;
        this.queryTimeout = setTimeout(() => this.query(), 3 * 1000)
      } catch {
        if (connectCount > 3) {
          this.connected = false;
          this.printer = null;
          // await this.disconnect()
          return
        }
        await new Promise(resolve => setTimeout(resolve, 1000));
        return this.query(connectCount + 1);
      }
    },
    showError(message = null) {
      this.errorMessage = message;
    }
  },
  async beforeDestroy () {
    await this.disconnect()
  },
  watch: {
    refetchKey: {
      immediate: true,
      handler () {
        if (this.publicUrl && this.token) {
          this.connect()
        }
      }
    },
  },
}
</script>