import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: "AIzaSyCQoL548-MnN1uRd6d21dwo8d8369Ga1y0",
  authDomain: "dev-mvp-283911.firebaseapp.com",
  databaseURL: "https://dev-mvp-283911.firebaseio.com",
  projectId: "dev-mvp-283911",
  storageBucket: "dev-mvp-283911.appspot.com",
  messagingSenderId: "373794064232",
  appId: "1:373794064232:web:d38cfdf6ff2c939fe138dd"
}
const fb = firebase.initializeApp(firebaseConfig)

// utils
const functions = fb.functions('europe-west3');
// if (window.location.hostname === 'localhost') functions.useFunctionsEmulator('http://localhost:5001');

const db = firebase.firestore()
const auth = firebase.auth()

const callable = function(address, data = null) {
  return new Promise((resolve, reject) => {
    functions.httpsCallable(address)(data)
      .then(({ data: { success, data, errorMessage } }) => {
        if(success) return resolve(data);
        reject(new Error(errorMessage));
      })
      .catch((error) => {
        // Do some global error handling here. All functions should return {success: true/false, data}
        if (error.code === 'INTERNAL' || error.code === 'internal') {
          reject(new Error('Conection error!'));
        } else {
          reject(new Error(error.message));
        }
      });
  })
}

// export utils/refs
export {
  firebase,
  db,
  auth,
  callable,
}