<template>
  <div id="app" :class="theme" class="w-full h-full bg-body">
    <transition name="fade" mode="out-in" appear>
      <router-view :key="parentView" />
    </transition>
    <portal-target name="modals" />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'App',
  computed: {
    ...mapState({
			theme: state => `theme-${state.core.theme}`
    }),
    parentView() {
      return this.$route.matched.some(record => record.meta.parent)
    }
  },
}
</script>
<style lang="css">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>