<template>
  <div class="relative" v-click-outside="hide">
    <input
      class="w-full appearance-none py-3 px-2 rounded-md leading-tight focus:outline-none border-2 border-primary"
      type="text"
      :placeholder="placeholder"
      :value="value"
      @change="$emit('change', $event.target.value)"
      @click="showDropdown = !showDropdown"
      v-on:keydown="hide"
    />
    <div
      @click="showDropdown = !showDropdown"
      class="absolute inset-y-0 right-0 flex items-center pr-2 hover:cursor-pointer"
    >
      <svg class="h-6 w-6" viewBox="0 0 24 24">
        <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
      </svg>
    </div>
    <ul
      class="absolute w-full border-2 border-primary bg-white divide-black z-10 rounded-md overflow-y-scroll"
      style="top: 40px; max-height: 200px"
      v-if="showDropdown"
    >
      <li
        class="py-2 px-4 hover:cursor-pointer"
        v-for="(item, index) in items"
        :key="item"
        @click.prevent="select(index)"
        v-bind:class="{'bg-fade': value == item}"
        v-text="item"
      />
      <li
        class="py-2 px-4"
        v-if="!items.length"
        @click.prevent
        v-text="'No results'"
      />
    </ul>
  </div>
</template>
<script>
import ClickOutside from 'vue-click-outside';

export default {
  name: 'Combobox',
  directives: {
    ClickOutside
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showDropdown: false,
      selectedIndex: 0,
    }
  },
  methods: {
    hide() {
      this.showDropdown = false
    },
    select(index) {
      this.showDropdown = false;
      this.$emit('change', this.items[index]);
    },
  },
}
</script>