<template>
  <div class="py-8 px-28">
    <h1>Organization log</h1>
    <div class="border-b border-primary pb-2 mb-4 flex">
      <div>Last 30 activities in your organization</div>
      <div class="ml-4">Go <router-link to="/history" v-text="'here'" /> to view your activities</div>
    </div>
    <div class="flex">
      <div class="rounded-lg border border-primary p-8">
        <table class="w-full">
          <thead>
            <tr>
              <td
                v-for="header in headers"
                :key="header.value"
              >
                <div v-text="header.text" />
              </td>
            </tr>
          </thead>
          <tbody v-if="searching">
            <tr>
              <td :colspan="headers.length" class="text-center">
                <div class="flex justify-center">
                  <svg class="animate-spin h-5 w-5" viewBox="0 0 24 24">
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="!organizationLog.length">
            <tr>
              <td :colspan="headers.length" class="text-center">
                No activities
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr
              v-for="log in organizationLog"
              :key="log.id"
            >
              <td>
                {{ log.created.time | formatTimestamp('yyyy-MM-dd HH:mm:ss') }}
              </td>
              <td>
                {{ $store.getters['organization/getUserName'](log.created.uid) }}
              </td>
              <td>
                <svg class="w-5 h-5" viewBox="0 0 24 24" v-tooltip="{ content: log.type.text, placement: 'right', offset: 4 }">
                  <path :d="log.type.icon" />
                </svg>
              </td>
              <td>
                <div v-html="log.text" />
              </td>
              <td>
                <router-link
                  v-if="log.reference"
                  :to="log.reference">
                  <svg class="w-5 h-5" viewBox="0 0 24 24">
                    <path d="M3.9,12C3.9,10.29 5.29,8.9 7,8.9H11V7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H11V15.1H7C5.29,15.1 3.9,13.71 3.9,12M8,13H16V11H8V13M17,7H13V8.9H17C18.71,8.9 20.1,10.29 20.1,12C20.1,13.71 18.71,15.1 17,15.1H13V17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7Z" />
                  </svg>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { db } from '@/plugins/firebase';
import { formatLog } from '@/services/formatting';

export default {
  name: 'OrganizationLogs',
  data() {
    return {
      organizationLog: [],
      searching: true,
      headers: [
        { text: 'Date', value: 'date'},
        { text: 'Created by', value: 'createdBy'},
        { text: 'Category', value: 'category' },
        { text: 'Action', value: 'action' },
        { text: 'Reference', value: 'reference' }
      ],
    }
  },
  methods: {
    getOrganizationLog() {
      this.searching = true;
      this.organizationLog.splice(0, this.organizationLog.length)
      const listener = db.collection(`organizations/${this.$store.state.organization.id}/log`)
                  .orderBy('created.time', 'desc')
                  .limit(30)
                  .onSnapshot((querySnap) => {
        querySnap.docChanges().forEach(({ type, doc, newIndex }) => {
          if('removed' === type) {
            this.organizationLog.splice(this.organizationLog.findIndex(({id}) => id === doc.id), 1);
          } else if('modified' === type) {
            this.organizationLog.splice(this.organizationLog.findIndex(({id}) => id === doc.id), 1);
            this.organizationLog.splice(newIndex, 0, formatLog(doc));
          } else if('added' === type) {
            this.organizationLog.splice(newIndex, 0, formatLog(doc));
          }
          this.searching = false;
        });
      }, (error) => {
        console.error(error);
        setTimeout(this.getOrganizationLog, 10000);
      });
      this.$store.commit('core/ADD_LISTENER', { id: 'organizationLog', listener });
    }
  },
  created() {
    this.getOrganizationLog()
  },
  beforeDestroy() {
    this.$store.commit('core/REMOVE_LISTENER', 'organizationLog');
  }
}
</script>
<style scoped>
tr > td:not(:last-child) {
  padding-right: 4rem;
}
</style>