<template>
  <div class="w-74 py-4 bg-fade border-r border-primary flex-shrink-0">
    <div class="mb-12">
      <h5 class="px-4 mb-2">Categories</h5>
      <div class="px-4 overflow-auto" style="max-height: 600px">
        <router-link
          v-for="cat in categories"
          :key="cat"
          v-text="cat"
          class="category"
          :class="{active: category === cat}"
          :to="{ name: 'library', query: { category: category === cat ? '' : cat, filter, orderBy, sort } }"
        />
      </div>
    </div>
    <div class="px-4 mb-12" v-if="isAdmin">
      <h5 class="mb-2">Status</h5>
      <router-link
        v-for="status in reviewStatuses"
        :key="status.text"
        class="category"
        :class="{active: status.text === filter}"
        :to="{ name: 'library', query: { category, filter: filter === status.text ? '' : status.text, orderBy, sort } }"
      >
        <svg class="h-5 w-5 mr-2 fill-current" :class="status.color" viewBox="0 0 24 24">
          <path :d="status.path" />
        </svg>
        <div v-text="status.text" />
      </router-link>
    </div>
  </div>
</template>
<script>
import { objectStatuses } from '@/services/formatting';

export default {
  name: 'Categories',
  props: {
    category: {
      type: String,
      default: '',
    },
    filter: {
      type: String,
      default: '',
    },
    orderBy: {
      type: String,
      default: 'updated'
    },
    sort: {
      type: String,
      default: 'desc'
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      reviewStatuses: objectStatuses
    }
  },
  computed: {
    categories() {
      return this.$store.getters['organization/categories'];
    },
  },
}
</script>
<style scoped>
.category {
  @apply px-3;
  @apply py-2;
  @apply font-medium;
  @apply text-md;
  @apply rounded-md;
  @apply text-primary;
  @apply block;
  @apply flex;
  @apply items-center;
}
.category:hover:not(.active) {
  @apply bg-light;
  @apply cursor-pointer;
}
.category.active {
  @apply bg-navbar-hover;
  @apply text-white;
}
</style>