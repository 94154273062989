<template>
  <Dropdown origin="origin-top-left">
    <template #activator>
      <Button
        color="primary"
        type="tight"
        :disabled="!adminRights(claim)"
      >
        {{ resourceName(claim.resource) | capitalize }}
        <svg v-if="adminRights(claim)" class="-mr-1 ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
      </Button>
    </template>
    <template>
      <button
        class="w-full text-left px-4 hover:bg-light hover:cursor-pointer block py-2 font-bold"
        v-for="resource in possibleNewResources(claim.resource)"
        :key="resource.value"
        @click="adminRights(claim) ? changeResource(claim, resource.value) : null"
      >
        {{ resource.text | capitalize}}
      </button>
    </template>
  </Dropdown>
</template>
<script>
import Dropdown from '@/components/base/Dropdown.vue';
import Button from '@/components/base/Button.vue';
import { callable } from '@/plugins/firebase';
export default {
  name: 'UserChangeResource',
  components: {
    Dropdown,
    Button,
  },
  props: {
    claim: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    adminRights(claim) {
      if(claim.role === 'owner') return false;
      return this.$store.getters['user/isAdmin'](claim.resource);
    },
    resourceName(resource) {
      if(resource === 'global') return 'All';
      const department = this.$store.getters['organization/getDepartment'](resource)
      return department ? department.name : '';
    },
    possibleNewResources(existing) {
      const allResources = [{ text: 'All', value: 'global' }];
      allResources.push(...this.$store.state.organization.departments.filter(i => i.id !== existing).map(i => ({ text: i.name, value: i.id })));
      return allResources;
    },
    changeResource(claim, newResource) {
      this.loading = true;
      callable('users-changeResource', {
          organizationId: this.$store.state.organization.id,
          claimId: claim.id,
          resource: newResource,
          email: claim.email
        })
        .then(() => { this.$emit('refresh-claims'); })
        .finally(() => { this.loading = false; })
    }
  },
}
</script>