<template>
  <v-popover
    offset="4"
    :placement="placement"
    trigger="hover click focus"
    container="#app"
    class="flex items-center"
  >
    <svg :class="`h-${size} w-${size} fill-current text-disabled tooltip-target hover:cursor-help`" viewBox="0 0 24 24">
      <path d="M13,9H11V7H13M13,17H11V11H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
    </svg>
    <template slot="popover">
      <div class="border border-primary p-2 bg-primary rounded-md text-sm text-white">
        <slot />
      </div>
    </template>
  </v-popover>
</template>
<script>
export default {
  name: 'Info',
  props: {
    size: {
      type: Number,
      default: 4,
    },
    placement: {
      type: String,
      default: 'right'
    }
  },
}
</script>