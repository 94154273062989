<template>
  <div class="py-4">
    <div class="px-6 mb-4">
      <div class="text-muted" v-text="'Total print time in queue'" />
      <div class="font-medium">
        {{ printer.queueMetaData.printTime | formatDuration({ short: true }) }}
      </div>
    </div>
    <div class="px-6 mb-4">
      <div class="text-muted" v-text="'Location'" />
      <EditableField
        v-if="isAdmin"
        :currentValue="printer.printerMetaData.location"
        :validations="locationValidations"
        :validationErrors="locationValidationErrors"
        :save="changeLocation"
        :maxWidth=120
        iconClass="h-4 w-4 mr-2"
        inputClass="pl-8 pr-2 py-1 font-medium"
      />
    </div>
    <div class="px-6">
      <div class="text-muted" v-text="'Materials'" />
      <div class="font-medium">
        <div
          v-for="(material, index) in materials"
          :key="index"
          v-text="material"
        />
      </div>
    </div>
  </div>
</template>
<script>
import EditableField from '@/components/base/EditableField';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'MetaData',
  components: {
    EditableField,
  },
  props: {
    printer: {
      type: Object,
      required: true
    },
    client: {
      type: Object,
      required: true
    },
    showError: {
      type: Function,
      required: true
    },
  },
  data () {
    return {
      locationValidations: { required },
      locationValidationErrors: {
        required: 'Location is required'
      },
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters['user/isAdmin'](this.printer.departmentId);
    },
    materials () {
      const materialA = this.printer.printerState.materials.trayA.id ?
        this.$store.getters['core/findMaterialById'](this.printer.printerState.materials.trayA.id)
        : 'None'
      const materialB = this.printer.printerState.materials.trayB.id ? 
        this.$store.getters['core/findMaterialById'](this.printer.printerState.materials.trayB.id)
        : 'None'
      return [
        `A: ${materialA || 'None'}`,
        `B: ${materialB || 'None'}`
      ]
    },
  },
  methods: {
    async changeLocation(location) {
      try {
        this.showError(null);
        this.loading = true;
        await this.client.setPrinterLocation(location.toString())
        await new Promise(resolve => setTimeout(resolve, 1000))
        await this.refresh()
      } catch(error) {
        console.error(error)
        this.showError(error.message);
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>