<template>
  <div class="px-4 h-16 border-b border-primary flex items-center">
    <EditableField
      v-if="isAdmin && client"
      :currentValue="metaData.name || 'No name'"
      :validations="nameValidations"
      :validationErrors="nameValidationErrors"
      :save="rename"
      iconClass="h-4 w-4 mr-2"
      inputClass="pl-8 pr-2 py-1 text-2xl font-bold"
    />
    <div v-else class="text-2xl font-bold" v-text="metaData.name || 'No name'" />
  </div>
</template>
<script>
import EditableField from '@/components/base/EditableField';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'PrinterName',
  components: {
    EditableField,
  },
  props: {
    printer: {
      type: Object,
      required: true
    },
    client: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      nameValidations: { required },
      nameValidationErrors: {
        required: 'Name is required'
      },
    }
  },
  computed: {
    metaData() {
      return this.printer.printerMetaData
    },
    isAdmin() {
      return this.$store.getters['user/isAdmin'](this.printer.departmentId);
    },
  },
  methods: {
    rename(name) {
      if (!this.client) return;
      return this.client.setPrinterName(name)
    },
  },
}
</script>