import { SET_DATA, ADD_TO_LIST, REMOVE_FROM_LIST, CLEAR_LIST } from './shared';

export default {
  namespaced: true,
  state: {
    isAuthenticated: false,
    uid: null,
    email: null,
    name: null,
    multiFactorNumber: null,
    superAdmin: false,
    claims: {},
    loading: true,
    logs: [],
    token: null,
  },
  mutations: {
    SET_DATA,
    ADD_TO_LIST,
    REMOVE_FROM_LIST,
    CLEAR_LIST
  },
  getters: {
    getClaim: (state, _getters, rootState) => !rootState.organization.id || !state.claims || !state.claims[rootState.organization.id] ? {} : state.claims[rootState.organization.id],
    getRole: (_state, getters) => getters.getClaim.role,
    getResource: (_state, getters) => getters.getClaim.resource,
    isAdmin: (_state, getters) => (departmentId = null) => ['admin','owner'].includes(getters.getRole) && ['global', departmentId].includes(getters.getResource),
    log: (state) => {
      const logs = [...state.logs];
      logs.sort((a, b) => b.created.time.toMillis() - a.created.time.toMillis())
      return logs;
    }
  }
};