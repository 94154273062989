class PrinterApiError extends Error {
  constructor (message, type) {
    super(message)

    if (!['USER', 'NETWORK', 'INTERNAL'].includes(type)) {
      throw new Error('Invalid printer API error type')
    }

    this.errorType = type
  }

  getType () {
    return this.errorType
  }
}

export default PrinterApiError
