const UNITS = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
const STEP = 1024;

function format(value, power) {
  return (value / Math.pow(STEP, power)).toFixed(0) + ' ' + UNITS[power];
}

export default function(value) {
  if (typeof value !== 'number' || isNaN(value)) return 'N/A';
  value = parseFloat(value, 10);
  for (var i = 0; i < UNITS.length; i++) {
    if (value < Math.pow(STEP, i)) {
      if (UNITS[i - 1]) return format(value, i - 1);
      return value + UNITS[i];
    }
  }
  return format(value, i - 1);
}