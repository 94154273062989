<template>
  <div class="h-full flex flex-col justify-between py-8 px-6 bg-cover relative" :style="`background-image: url(${bgSrc});`">
    <div class="pb-8">
      <a
        href="https://rapid-dimension.com"
        class="flex items-center text-white">
        <svg class="h-6 w-6 fill-current" viewBox="0 0 24 24">
          <path d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"></path>
        </svg>
        Go to www.rapid-dimension.com
      </a>
    </div>
    <div class="flex-grow flex justify-center">
      <div class="w-108 mt-8 md:mt-24 xl:mt-48">
        <div class="w-10/12 mx-auto mb-6">
          <img alt="Rapid Dimension" src="/images/rdfull_white.png" class="w-full" />
        </div>
        <div class="border border-primary px-8 py-12 bg-white shadow-lg rounded-lg">
          <router-view />
        </div>
      </div>
    </div>
    
    <div class="flex justify-center pt-8 space-x-8">
      <a href="https://rapid-dimension.com" class="font-medium text-white">© Rapid Dimension</a>
      <a href="mailto:info@rapid-dimension.com" class="font-medium text-white">Contact</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginView",
  data() {
    return {
      bgSrc: `${process.env.BASE_URL}bg.webp`
    }
  }
};
</script>