import { intervalToDuration, formatDuration } from 'date-fns';

export default function(interval, { short = false, format = ['days', 'hours', 'minutes'] } = { short: false, format: ['days', 'hours', 'minutes'] }) {
  if (typeof interval !== 'number' || Number.isNaN(interval)) return 'N/A'
  if (interval === 0) return '-'
  const start = Date.now()
  let duration = formatDuration(intervalToDuration({
    start,
    end: start + interval * 1000
  }), {
    format,
    zero: false
  })
  if (short) {
    duration = duration
      .replace('days', 'd')
      .replace('day', 'd')
      .replace('hours', 'h')
      .replace('hour', 'h')
      .replace('minutes', 'm')
      .replace('minute', 'm')
  }
  return duration
}