<template>
  <div class="flex flex-col flex-grow h-full">
    <div
      v-if="object === false"
      class="flex-grow flex flex-col justify-center items-center text-muted text-xl"
    >
      <svg class="w-12 h-12 fill-current" viewBox="0 0 24 24"><path d="M22.11 21.46L2.39 1.73L1.11 3L4.3 6.19L3.53 6.62C3.21 6.79 3 7.12 3 7.5V16.5C3 16.88 3.21 17.21 3.53 17.38L11.43 21.82C11.59 21.94 11.79 22 12 22S12.41 21.94 12.57 21.82L17.28 19.17L20.84 22.73L22.11 21.46M5 15.91V9.21L10.29 12.18L11 12.89V19.29L5 15.91M13 19.29V14.89L15.82 17.7L13 19.29M9 5.82L7.56 4.36L11.43 2.18C11.59 2.06 11.79 2 12 2S12.41 2.06 12.57 2.18L20.47 6.62C20.79 6.79 21 7.12 21 7.5V16.5C21 16.85 20.82 17.16 20.53 17.33L19 15.8V9.21L14.78 11.58L13.31 10.11L17.96 7.5L12 4.15L9 5.82Z" /></svg>
      <div class="my-2">Object not found</div>
      <Button
        @click="$router.push('/library')"
      >
        Back to library
      </Button>
    </div>
    <div
      v-else-if="object === null"
      class="flex-grow flex justify-center items-center"
    >
      <svg class="animate-spin h-8 w-8 fill-current" viewBox="0 0 24 24">
        <path class="opacity-75" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>
    <div v-else class="flex flex-grow h-full">
      <div class="flex-grow flex flex-col">
        <div class="px-4 h-16 border-b border-primary flex items-center justify-between">
          <div class="flex-shrink">
            <EditableField
              v-if="isAdmin"
              :currentValue="object.name"
              :validations="nameValidations"
              :validationErrors="nameValidationsErrors"
              :save="($event) => rename($event)"
              iconClass="h-4 w-4 mr-2"
              inputClass="pl-8 pr-2 py-1 text-2xl font-bold w-full"
            />
            <div v-else class="text-2xl font-bold" v-text="object.name" />
          </div>
          <div class="flex flex-shrink-0 items-center space-x-4">
            <!-- <CreateGenerationFromGcode
              v-if="isAdmin"
              :objectId="objectId"
            /> -->
            <ObjectQueue
              v-if="generation && !object.deleted"
              :object="object"
              :generation="generation"
              :readyToPrint="readyToPrint"
            >
              <template #activator>
                <Button
                  @click="printModal = true"
                  :disabled="!readyToPrint"
                >
                  <svg class="h-5 w-5 mr-2 fill-current text-white" viewBox="0 0 24 24">
                    <path d="M19,6A1,1 0 0,0 20,5A1,1 0 0,0 19,4A1,1 0 0,0 18,5A1,1 0 0,0 19,6M19,2A3,3 0 0,1 22,5V11H18V7H6V11H2V5A3,3 0 0,1 5,2H19M18,18.25C18,18.63 17.79,18.96 17.47,19.13L12.57,21.82C12.4,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L6.53,19.13C6.21,18.96 6,18.63 6,18.25V13C6,12.62 6.21,12.29 6.53,12.12L11.43,9.68C11.59,9.56 11.79,9.5 12,9.5C12.21,9.5 12.4,9.56 12.57,9.68L17.47,12.12C17.79,12.29 18,12.62 18,13V18.25M12,11.65L9.04,13L12,14.6L14.96,13L12,11.65M8,17.66L11,19.29V16.33L8,14.71V17.66M16,17.66V14.71L13,16.33V19.29L16,17.66Z" />
                  </svg>
                  Queue
                </Button>
              </template>
            </ObjectQueue>
          </div>
        </div>
        <ObjectView
          class="flex-grow"
          :generation="generation"
        />
      </div>
      <div id="details" class="w-84 flex-shrink-0 bg-fade border-l border-primary tabs flex flex-col items-stretch">
        <div
          v-for="tab in filteredTabs"
          :key="tab.component"
          :class="{'active': currentTab === tab.component}"
        >
          <div @click="currentTab = tab.component === currentTab ? null : tab.component">
            <div v-text="tab.text" />
            <svg class="w-6 h-6 fill-current" viewBox="0 0 24 24">
              <path v-if="currentTab === tab.component" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
              <path v-else d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
            </svg>
          </div>
          <component
            v-show="currentTab === tab.component"
            style="max-height: 800px"
            class="overflow-auto"
            :is="tab.component"
            :object="object"
            :generation="generation"
            :readyToPrint="readyToPrint"
            :objectId="objectId"
            :generationId="generationId"
            :key="tab.component"
            :isAdmin="isAdmin"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { callable } from '@/plugins/firebase';
import { db } from '@/plugins/firebase';
import EditableField from '@/components/base/EditableField';
import Button from '@/components/base/Button';
import { required } from 'vuelidate/lib/validators';
import ObjectDetails from './ObjectDetails';
import ObjectVersions from './ObjectVersions';
import ObjectQueue from './ObjectQueue';
import ObjectView from './ObjectView';
// import CreateGenerationFromGcode from './CreateGenerationFromGcode';

export default {
  name: 'Objects',
  components: {
    EditableField,
    Button,
    ObjectDetails,
    ObjectVersions,
    ObjectQueue,
    ObjectView,
    // CreateGenerationFromGcode,
  },
  props: {
    objectId: {
      type: String
    },
    generationId: {
      type: String
    }
  },
  data() {
    return {
      loadingGeneration: true,
      loadingObject: true,
      objectListener: null,
      generationListener: null,
      object: null,
      generation: null,
      nameValidations: { required },
      nameValidationsErrors: {
        required: 'Name is required'
      },
      tabs: [
        { text: 'Details', component: 'ObjectDetails', admin: false },
        { text: 'Versions', component: 'ObjectVersions', admin: true }
      ],
      currentTab: 'ObjectDetails'
    }
  },
  computed: {
    filteredTabs() {
      if(this.isAdmin) return this.tabs;
      return this.tabs.filter(({ admin }) => !admin)
    },
    loading() {
      return this.loadingGeneration || this.loadingObject;
    },
    foundObject() {
      return this.object && this.generation;
    },
    mergedObjectGeneration() {
      if (!this.object) return null;
      return { ...this.object, generation: this.generation };
    },
    isAdmin() {
      return this.$store.getters['user/isAdmin']();
    },
    readyToPrint() {
      return this.generation && this.generation.active && this.generation.availableMultipliers.length > 0 && !this.object.deleted;
    },
  },
  methods: {
    rename(name) {
      return callable('objects-rename', {
        organizationId: this.$store.state.organization.id,
        objectId: this.objectId,
        name,
      })
    },
    fetchObject() {
      this.$store.commit('core/REMOVE_LISTENER', 'object');
      const listener = db.doc(`organizations/${this.$store.state.organization.id}/objects/${this.objectId}`).onSnapshot(objectSnap => {
        this.$set(this, 'object', objectSnap.exists ? objectSnap.data() : false)
        if (!objectSnap.exists) {
          this.$set(this, 'generation', false)
        } else if (!this.generationId && this.object.generation && this.object.generation.id) {
          this.$router.replace(`/library/${this.objectId}?generation=${this.object.generation.id}`);
        }
      });
      this.$store.commit('core/ADD_LISTENER', { id: 'object', listener });
    },
    fetchGeneration() {
      this.$store.commit('core/REMOVE_LISTENER', 'generation');
      const listener = db.doc(`organizations/${this.$store.state.organization.id}/objects/${this.objectId}/generations/${this.generationId}`).onSnapshot(genSnap => {
        this.$set(this, 'generation', genSnap.exists ? genSnap.data() : false)
      });
      this.$store.commit('core/ADD_LISTENER', { id: 'generation', listener });
    },
  },
  beforeDestroy() {
    this.$store.commit('core/REMOVE_LISTENER', 'object');
    this.$store.commit('core/REMOVE_LISTENER', 'generation');
  },
  watch: {
    objectId: {
      immediate: true,
      handler () {
        this.fetchObject()
      }
    },
    generationId: {
      immediate: true,
      handler (generationId) {
        if (generationId) this.fetchGeneration()
      }
    }
  }
}
</script>
<style scoped>
.tabs > div > div:first-child {
  @apply p-4;
  @apply flex;
  @apply justify-between;
  @apply font-medium;
  @apply text-md;
  @apply tracking-wide;
  @apply uppercase;
  @apply text-muted;
  @apply border-primary;
}
.tabs > div:not(:first-child) > div:first-child {
  @apply border-t;
}
.tabs > div:last-child:not(.active) > div:first-child {
  @apply border-b;
}
.tabs > div.active {
  @apply flex-grow;
}
.tabs > div > div:first-child:hover {
  @apply cursor-pointer;
  @apply text-primary;
}
</style>