<template>
  <div>
    <h3 class="mb-6">Cura link successful</h3>
    <p class="mb-6">
      You're user account is now linked to your local Cura application and can make requests on your behalf.
    </p>
    <h4>Switch back to Cura to start using the plugin</h4>
  </div>
</template>
<script>
export default {
  name: 'Cura',
  data() {
    return {
      email: '',
      loading: false,
      success: false,
      error: null,
    }
  },
  mounted() {
    window.location.href = 'cura://open.test'
  },
}
</script>