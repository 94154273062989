<template>
  <div>
    <form @submit.prevent="submit">
      <h3 class="mb-6">Reset password</h3>
      <p class="mb-6">
        Enter the email address associated with your account and we'll send you a link to reset your password.
      </p>
      <div class="mb-2">
        <label for="email">Email</label>
      </div>
      <input
        name="email"
        autocomplete="email"
        class="w-full p-4 rounded mb-6"
        :class="$v.email.$dirty && $v.email.$invalid ? 'border-2 border-error' : 'border border-primary'"
        type="email"
        v-model.trim="$v.email.$model"
      />
      <Button
        color="primary"
        type="wide"
        class="w-full"
        :loading="loading"
      >
        Send reset link
      </Button>
      <div v-if="success" class="text-success mt-4" v-text="'A reset link has been sent to your email!'" />
      <div v-if="error" class="text-error mt-4" v-text="error" />
      <div class="flex justify-center mt-6">
        <router-link
          to="/login"
          class="text-secondary font-medium hover:cursor-pointer"
          v-text="'Back'"
        />
      </div>
    </form>
  </div>
</template>
<script>
import { required, email } from 'vuelidate/lib/validators'
import Button from '@/components/base/Button.vue';
import { sendPasswordResetEmail, errorDisplay } from '@/services/auth';

export default {
  name: 'RecoverPassword',
  components: {
    Button,
  },
  data() {
    return {
      email: '',
      loading: false,
      success: false,
      error: null,
    }
  },
  validations: {
    email: {
      required,
      email
    },
  },
  methods: {
    submit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.loading = true;
        this.success = false;
        this.error = null;
        sendPasswordResetEmail(this.email)
          .then(() => { this.success = true; })
          .catch((error) => { this.error = errorDisplay(error); })
          .finally(() => { this.loading = false; })
      }
    }
  },
}
</script>