import { auth } from '@/plugins/firebase';

// Views
import MainView from "@/views/Main.vue";
import LoginView from "@/views/Login.vue";
import LibraryView from "@/views/Library.vue";

// Main View
import Library from "@/components/library/Library.vue";
import Objects from "@/components/objects/Objects.vue";
import MyLog from "@/components/logs/MyLog.vue";
import Printers from "@/components/printers/Printers.vue";
import SlicerInstallation from "@/components/other/SlicerInstallation.vue";
import Restock from "@/components/Restock.vue";
import Reports from "@/components/Reports.vue";
import Account from "@/components/account/Account.vue";
import Organization from "@/components/organization/Organization.vue";
import Users from "@/components/users/Users.vue";
import OrganizationLogs from "@/components/logs/OrganizationLogs.vue";

import Colors from "@/components/Colors.vue";

// Login view
import Login from "@/components/login/Login.vue";
import ResetPassword from "@/components/login/ResetPassword.vue";
import Cura from "@/components/login/Cura.vue";

import store from '@/store'

const isAuthenticated = () => auth.currentUser && auth.currentUser.emailVerified

const requiresAuth = (to, _from, next) => {
  if (!isAuthenticated()) return next({ path: '/login', query: { route: to.path }});
  next();
}

export default [
  {
    path: "/",
    component: MainView,
    meta: {
      parent: 'main',
      requiresAuth: true,
    },
    beforeEnter: requiresAuth,
    children: [
      { 
        path: '',
        redirect: '/library'
      }, {
        path: 'library',
        component: LibraryView,
        children: [
          {
            path: '',
            name: 'library',
            component: Library,
            props: route => ({
              category: route.query.category,
              filter: route.query.filter,
              orderBy: route.query.orderBy,
              sort: route.query.sort
            })
          }, {
            path: ':objectId?',
            name: 'objects',
            component: Objects,
            props: route => ({
              objectId: route.params.objectId,
              generationId: route.query.generation
            })
          }
        ]
      }, {
        path: 'printers/:printerId?',
        name: 'printers',
        props: true,
        component: Printers
      }, {
        path: 'history',
        name: 'history',
        component: MyLog
      },{
        path: 'restock',
        name: 'restock',
        component: Restock
      },{
        path: 'slicer-installation',
        name: 'slicer-installation',
        component: SlicerInstallation
      },{
        path: 'reports',
        name: 'reports',
        component: Reports
      },{
        path: 'account',
        name: 'account',
        component: Account
      },{
        path: 'settings',
        name: 'settings',
        component: Organization,
      },{
        path: 'users',
        name: 'users',
        component: Users,
      },{
        path: 'logs',
        name: 'logs',
        component: OrganizationLogs
      },{
        path: 'colors',
        component: Colors
      }
    ]
  },
  {
    path: "/login",
    component: LoginView,
    meta: {
      parent: 'login',
    },
    children: [
      {
        path: '',
        name: 'login',
        component: Login,
        beforeEnter: async (to, _from, next) => {
          if (!isAuthenticated()) return next();
          const {
            query: {
              route,
              callback
            },
            path
          } = to
          if (callback) {
            // If callback call callback with tokens
            const tokenResult = await auth.currentUser.getIdTokenResult(false)
            await new Promise(resolve => {
              const interval = setInterval(() => {
                if (store.state.organization.id) {
                  clearInterval(interval)
                  resolve()
                }
              }, 100)
            })
            const authResponse = {
              organizationId: store.state.organization.id,
              refreshToken: auth.currentUser.refreshToken,
              accessToken: tokenResult.token,
              expires: Date.parse(tokenResult.expirationTime)
            }
            window.location = `${callback}?${new URLSearchParams(authResponse).toString()}`;
          } else if (route && route !== path) {
            // If redirect specified a querystring path if not already there
            next(route)
          } else {
            next('/')
          }
        }
      }, {
        path: 'reset',
        name: 'reset',
        component: ResetPassword
      }, {
        path: 'cura',
        name: 'cura',
        component: Cura,
        beforeEnter: requiresAuth,
      }
    ]
  },
];