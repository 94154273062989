<template>
  <div class="py-8 px-28">
    <h1>My log</h1>
    <div class="border-b border-primary pb-2 mb-4 flex">
      <div>Your last 30 activities</div>
      <div class="ml-4" v-if="$store.getters['user/isAdmin']()">Go <router-link to="/logs" v-text="'here'" /> to view activities in the organization</div>
    </div>
    <div class="flex">
      <div class="rounded-lg border border-primary p-8">
        <table class="w-full">
          <thead>
            <tr>
              <td
                v-for="header in headers"
                :key="header.value"
              >
                <div v-text="header.text" />
              </td>
            </tr>
          </thead>
          <tbody v-if="!myLog.length">
            <tr>
              <td :colspan="headers.length" class="text-center">
                No activities
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr
              v-for="log in myLog"
              :key="log.id"
            >
              <td>
                {{ log.created.time | formatTimestamp('yyyy-MM-dd HH:mm:ss') }}
              </td>
              <td>
                <svg class="w-5 h-5" viewBox="0 0 24 24" v-tooltip="{ content: log.type.text, placement: 'right', offset: 4 }">
                  <path :d="log.type.icon" />
                </svg>
              </td>
              <td>
                <div v-html="log.text" />
              </td>
              <td>
                <router-link
                  v-if="log.reference"
                  :to="log.reference">
                  <svg class="w-5 h-5" viewBox="0 0 24 24">
                    <path d="M3.9,12C3.9,10.29 5.29,8.9 7,8.9H11V7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H11V15.1H7C5.29,15.1 3.9,13.71 3.9,12M8,13H16V11H8V13M17,7H13V8.9H17C18.71,8.9 20.1,10.29 20.1,12C20.1,13.71 18.71,15.1 17,15.1H13V17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7Z" />
                  </svg>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MyLog',
  data() {
    return {
      headers: [
        { text: 'Date', value: 'date'},
        { text: 'Category', value: 'category' },
        { text: 'Action', value: 'action' },
        { text: 'Reference', value: 'reference' }
      ],
    }
  },
  computed: {
    myLog() {
      return this.$store.getters['user/log'];
    }
  }
}
</script>
<style scoped>
tr > td:not(:last-child) {
  padding-right: 4rem;
}
</style>