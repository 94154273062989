import { format } from 'date-fns';
import { firebase } from '@/plugins/firebase';

export default function(timestamp, pattern = 'd MMM yyyy') {
  if(
    !(timestamp instanceof firebase.firestore.Timestamp) &&
    !(timestamp instanceof Date)
  ) return 'N/A';
  let date
  if(timestamp instanceof firebase.firestore.Timestamp) {
    date = timestamp.toDate();
  } else if(timestamp instanceof Date) {
    date = timestamp
  }
  return format(date, pattern);
}