<template>
  <div class="mx-8 my-4" v-on:click="sendMessage">
    <h2 class="border-b border-primary pb-3 mb-5">Contact</h2>
    <p class="max-w-xs border-b border-primary pb-5 mb-5">
      For emergency issues, please call us directly at <a href="tel:+46706847020">+46 (0)706 84 70 20</a>.
    </p>
    <p class="max-w-xs mb-8">
      For all other non-critical issues, feedback, suggestions etc. you can either email us at <a href="mailto:support@rapid-dimension.com">support@rapid-dimension.com</a> or send a message below.
    </p>
    <div class="mb-2">
      <label for="message">Message</label>
    </div>
    <textarea
      rows=4
      name="message"
      class="w-full p-4 mb-6"
      type="text"
      :class="[$v.message.$dirty && $v.message.$invalid ? 'border-2 border-error' : 'border border-primary']"
      v-model.trim="$v.message.$model"
    />
    <Button
      color="primary"
      type="wide"
      class="w-full"
      name="sendMessage"
    >
      Send
    </Button>
  </div>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
import Button from '@/components/base/Button.vue';
import { db } from '@/plugins/firebase';

export default {
  name: 'Contact',
  components: {
    Button
  },
  data() {
    return {
      message: '',
    }
  },
  validations: {
    message: {
      required,
    },
  },
  methods: {
    async sendMessage(event) {
      if(event.target.name === 'sendMessage') {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          db.collection(`users/${this.$store.state.user.uid}/emails`).add({ 
            type: 'message',
            message: this.message
          })
          .finally(() => { this.$emit('click'); })
        } else {
          event.preventDefault();
          event.stopPropagation();  
        }
      } else {
        event.preventDefault();
        event.stopPropagation();
      }
    }
  }
}
</script>

