<template>
  <table>
    <thead>
      <tr>
        <td
          v-for="header in headers"
          :key="header.value"
        >
          <div class="flex">
            <div v-text="header.text" />
            <Info v-if="!!header.info" class="ml-2" :placement="header.placement" :size=4>
              <div v-html="header.info" />
            </Info>
          </div>
        </td>
      </tr>
    </thead>
    <tbody v-if="!departments.length">
      <tr>
        <td :colspan="headers.length" class="text-center">
          No departments
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr
        v-for="department in departments"
        :key="department.id"
      >
        <td>
          <EditableField
            :currentValue="department.name"
            :validations="departmentNameValidations"
            :validationErrors="departmentNameErrors"
            :save="($event) => saveName(department.id, $event)"
          />
        </td>
        <td align="right">
          {{ getPrintersCount(department.id) }}
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import Info from '@/components/base/Info.vue';
import EditableField from '@/components/base/EditableField';
import { required } from 'vuelidate/lib/validators';
import { callable } from '@/plugins/firebase';

export default {
  name: 'Departments',
  components: {
    Info,
    EditableField
  },
  data() {
    return {
      headers: [
        { text: 'Name', value: 'name'},
        { text: '# of printers', value: 'printers'}
      ],
      departmentNameValidations: { required },
      departmentNameErrors: {
        required: 'Name is required'
      },
    }
  },
  computed: {
    departments() {
      return this.$store.state.organization.departments;
    }
  },
  methods: {
    getPrintersCount(departmentId) {
      return this.$store.getters['organization/printersInDepartment'](departmentId).length
    },
    saveName(departmentId, name) {
      return callable('departments-rename', {
        organizationId: this.$store.state.organization.id,
        departmentId,
        name
      })
    }
  }
}
</script>
