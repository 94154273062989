import store from '../store';
import { auth, db } from '../plugins/firebase';
import { formatLog } from './formatting';

const noUser = {
  isAuthenticated: false,
  uid: null,
  email: null,
  enrolledFactors: [],
  superAdmin: false,
  claims: {},
  loading: true,
  name: null
}

const noOrganization = {
  id: null,
  name: null,
  allowedDomains: [],
  departments: [],
  loading: true,
}

const stateChanges = async (user) => {
  if (user && user.emailVerified) {
    // Store user
    if(store.state.user.isAuthenticated) await signOut();
    return signIn(user);
  } else if(store.state.user.isAuthenticated) {
    signOut();
  }
}

// Signin and out
const signIn = async (user) => {
  store.commit('user/SET_DATA', {
    uid: user.uid,
    email: user.email,
    enrolledFactors: user.multiFactor.enrolledFactors,
    isAuthenticated: true
  });
  await getUser();
  await getClaims();
  getOrganization();
  departmentsListener();
  printerListener();
  getOrganizationUsers();
  getCategories();
  getLogs();
  getMaterials();
  getMetadata();
  getUpdates();
}

const signOut = async (userRequest = false) => {
  // Core
  store.commit('core/CLEAR_LISTENERS');
  store.commit('core/CLEAR_LIST', 'updates');
  // User
  store.commit('user/SET_DATA', { ...noUser });
  store.commit('user/CLEAR_LIST', 'logs');
  // Organization
  store.commit('organization/SET_DATA', { ...noOrganization });
  store.commit('organization/CLEAR_LIST', 'categories');
  store.commit('organization/CLEAR_LIST', 'departments');
  store.commit('organization/CLEAR_LIST', 'printers');
  store.commit('organization/CLEAR_LIST', 'materials');
  if(userRequest) return auth.signOut();
}

// User
const getUser = () => {
  if(!store.state.user.isAuthenticated) throw Error('Not logged in');
  store.commit('core/REMOVE_LISTENER', 'user');
  return new Promise((resolve) => {
    const listener = db.doc(`users/${store.state.user.uid}`).onSnapshot((docSnap) => {
      if(docSnap.exists) {
        store.commit('user/SET_DATA', { loading: false });
        store.commit('organization/SET_DATA', { id: docSnap.data().organizationId });
        if(resolve) resolve();
      }
    }, (error) => {
      console.error(error);
      setTimeout(getUser(), 10000);
    });
    store.commit('core/ADD_LISTENER', { id: 'user', listener });
  });
}

const getMetadata = () => {
  if(!store.state.user.isAuthenticated) throw Error('Not logged in');
  store.commit('core/REMOVE_LISTENER', 'metaData');
  const listener = db.doc(`users/${store.state.user.uid}/metaData/${store.state.organization.id}`).onSnapshot((docSnap) => {
    if(docSnap.exists) {
      store.commit('user/SET_DATA', { name: docSnap.data().name });
    }
  }, (error) => {
    console.error(error);
    setTimeout(getMetadata(), 10000);
  });
  store.commit('core/ADD_LISTENER', { id: 'metaData', listener });
}

const getClaims = async (tries = 1) => {
  if(!store.state.user.isAuthenticated) throw Error('Not logged in');
  try {
    const { token, expirationTime, claims: { superAdmin, claims = {}} } = await auth.currentUser.getIdTokenResult(true)
    store.commit('user/SET_DATA', { token, superAdmin, claims })
    window.document.cookie = `Authorization=Bearer ${token}; path=/; expires=${expirationTime}; domain=.rapid-dimension.com; secure`;
    // Refresh after 55 minutes
    setTimeout(getClaims, 55 * 60 * 1000)
  } catch (error) {
    console.error(error)
    await new Promise(resolve => setTimeout(resolve, Math.min(15000, tries * 2 * 1000)))
    return getClaims(tries + 1)
  }
}

// Organization
const getOrganization = async () => {
  if(!store.state.user.isAuthenticated) throw Error('Not logged in');
  store.commit('core/REMOVE_LISTENER', 'organization');
  const listener = db.doc(`organizations/${store.state.organization.id}`).onSnapshot((docSnap) => {
    if(docSnap.exists) {
      const { name, allowedDomains } = docSnap.data();
      store.commit('organization/SET_DATA', { name, allowedDomains, loading: false });
    }
  }, (error) => {
    console.error(error);
    setTimeout(getOrganization(), 10000);
  });
  store.commit('core/ADD_LISTENER', { id: 'organization', listener });
}

// Organization users
const getOrganizationUsers = () => {
  if(!store.state.user.isAuthenticated) throw Error('Not logged in');
  store.commit('core/REMOVE_LISTENER', 'organizationUsers');
  const listener = db.collection(`organizations/${store.state.organization.id}/usersMetaData`).onSnapshot((querySnap) => {
    const docChanges = querySnap.docChanges();
    docChanges.forEach(({ type, doc }) => {
      if(['removed','modified'].includes(type)) store.commit('organization/REMOVE_FROM_LIST', { key: 'users', id: doc.id });
      if(['added','modified'].includes(type)) store.commit('organization/ADD_TO_LIST', { key:'users', value: { id: doc.id, name: doc.data().name, email: doc.data().email } });
    });
  }, (error) => {
    console.error(error);
    setTimeout(getOrganizationUsers, 10000);
  });
  store.commit('core/ADD_LISTENER', { id: 'organizationUsers', listener });
}

// Departments
const departmentsListener = () => {
  if(!store.state.user.isAuthenticated) throw Error('Not logged in');
  store.commit('core/REMOVE_LISTENER', 'departments');
  const listener = db.collection('organizations').doc(store.state.organization.id).collection('departments').onSnapshot((querySnap) => {
    const docChanges = querySnap.docChanges();
    docChanges.forEach(({ type, doc }) => {
      if(['removed','modified'].includes(type)) store.commit('organization/REMOVE_FROM_LIST', { key: 'departments', id: doc.id });
      if(['added','modified'].includes(type)) store.commit('organization/ADD_TO_LIST', { key: 'departments', value: { id: doc.id, name: doc.data().name } });
    });
  }, (error) => {
    console.error(error);
    setTimeout(departmentsListener, 10000);
  });
  store.commit('core/ADD_LISTENER', { id: 'departments', listener });
}

// Printers
const printerListener = () => {
  if(!store.state.user.isAuthenticated) throw Error('Not logged in');
  store.commit('core/REMOVE_LISTENER', 'printers');
  const listener = db.collection('organizations')
                      .doc(store.state.organization.id)
                      .collection('printers')
                      .onSnapshot((querySnap) => {
    const docChanges = querySnap.docChanges();
    docChanges.forEach(({ type, doc }) => {
      if(['removed','modified'].includes(type)) store.commit('organization/REMOVE_FROM_LIST', { key: 'printers', id: doc.id });
      if(['added','modified'].includes(type)) store.commit('organization/ADD_TO_LIST', { key: 'printers', value: doc.data() });
    });
  }, (error) => {
    console.error(error);
    setTimeout(printerListener, 10000);
  });
  store.commit('core/ADD_LISTENER', { id: 'printers', listener });
}

// Categories
const getCategories = () => {
  if(!store.state.user.isAuthenticated) throw Error('Not logged in');
  store.commit('core/REMOVE_LISTENER', 'categories');
  const listener = db.doc(`organizations/${store.state.organization.id}/objects/categories`).onSnapshot((docSnap) => {
    if(docSnap.exists) store.commit('organization/SET_LIST', { key: 'categories', values: [...docSnap.data().categories] });
  }, (error) => {
    console.error(error);
    setTimeout(getCategories(), 10000);
  });
  store.commit('core/ADD_LISTENER', { id: 'categories', listener });
}

// Get materials
const getMaterials = () => {
  if(!store.state.user.isAuthenticated) throw Error('Not logged in');
  store.commit('core/REMOVE_LISTENER', 'materials');
  const listener = db.collection('materials').onSnapshot((querySnap) => {
    const docChanges = querySnap.docChanges();
    docChanges.forEach(({ type, doc }) => {
      if(['removed','modified'].includes(type)) store.commit('core/REMOVE_FROM_LIST', { key: 'materials', id: doc.id });
      if(['added','modified'].includes(type)) store.commit('core/ADD_TO_LIST', { key: 'materials', value: doc.data() });
    });
  }, (error) => {
    console.error(error);
    setTimeout(getMaterials, 10000);
  });
  store.commit('core/ADD_LISTENER', { id: 'materials', listener });
}

// Get my log
const getLogs = async () => {
  if(!store.state.user.isAuthenticated) throw Error('Not logged in');
  store.commit('core/REMOVE_LISTENER', 'myLog');
  store.commit('user/CLEAR_LIST', 'logs');
  const listener = db.collection(`organizations/${store.state.organization.id}/log`)
                  .where('created.uid', '==', store.state.user.uid)
                  .orderBy('created.time', 'desc')
                  .limit(30)
                  .onSnapshot((querySnap) => {
    querySnap.docChanges().forEach(({ type, doc }) => {
      if(['modified','removed'].includes(type)) store.commit('user/REMOVE_FROM_LIST', { key: 'logs', id: doc.id });
      if(['modified','added'].includes(type)) {
        store.commit('user/ADD_TO_LIST', { key: 'logs', value: formatLog(doc)});
      }
    });
  }, (error) => {
    console.error(error);
    setTimeout(getLogs(), 10000);
  });
  store.commit('core/ADD_LISTENER', { id: 'myLog', listener });
}

// Get updates
const getUpdates = () => {
  store.commit('core/REMOVE_LISTENER', 'updates');
  const listener = db.collection(`updates`).limit(10).onSnapshot((querySnap) => {
    const docChanges = querySnap.docChanges();
    docChanges.forEach(({ type, doc }) => {
      if(['removed','modified'].includes(type)) {
        store.commit('core/REMOVE_FROM_LIST', { key: 'updates', id: doc.id });
      }
      if(['added','modified'].includes(type)) {
        store.commit('core/ADD_TO_LIST', { key: 'updates', value: { id: doc.id, ...doc.data() } });
      }
    });
  }, (error) => {
    console.error(error);
    setTimeout(getUpdates(), 10000);
  });
  store.commit('core/ADD_LISTENER', { id: 'updates', listener });
}

export {
  stateChanges,
  signOut,
}