<template>
  <div>
    <div @click="open">
      <slot name="activator" />
    </div>
    <Modal
      :open="moveOpen"
      @close="moveOpen = false"
      :loading="moving"
    >
      <form
        @submit.prevent="move"
        class="bg-white rounded-lg shadow-2xl px-8 py-6 w-84"
      >
        <h2
          class="border-b border-primary pb-3 mb-5"
          v-text="'Change category'"
        />
        <Combobox
          class="mb-6"
          :items="categories"
          :value="newCategory"
          placeholder="Select / enter category"
          @change="newCategory = $event"
        />
        <Button
          type="wide"
          class="w-full"
        >
          Change category
        </button>
      </form>
    </Modal>
  </div>  
</template>
<script>
import { callable } from '@/plugins/firebase';
import Button from '@/components/base/Button.vue';
import Combobox from '@/components/base/Combobox.vue';
import Modal from '@/components/base/Modal.vue';

export default {
  name: 'ChangeCategory',
  components: {
    Modal,
    Button,
    Combobox
  },
  props: {
    category: {
      type: String,
      default: null,
    },
    objectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      moveOpen: false,
      moving: false,
      newCategory: this.category,
    }
  },
  computed: {
    categories() {
      return this.$store.getters['organization/categories'];
    },
  },
  methods: {
    open() {
      this.moveOpen = true;
    },
    move() {
      this.moving = true;
      callable('objects-move', {
        organizationId: this.$store.state.organization.id,
        objectId: this.objectId,
        category: this.newCategory || null
      })
        .finally(() => {
          this.moving = false;
          this.moveOpen = false;
          this.$emit('close');
        })
    },
  }
}
</script>