<template>
  <div class="flex h-full">
    <div class="w-auto flex-shrink-0 p-4 bg-fade border-r border-primary">
      <h5>Printers</h5>
      <PrinterList
        :selectedPrinter="printerId"
        v-on:printerSelect="showPrinter($event)"
      />
    </div>
    <div class="flex-grow">
      <div v-if="!printers" class="w-full h-full flex flex-col items-center justify-center text-muted text-xl">
        <svg class="w-7 h-7 fill-current mb-2" viewBox="0 0 24 24"><path d="M11,15H13V17H11V15M11,7H13V13H11V7M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20Z" /></svg>
        <div class="text-sm">No printers available</div>
      </div>
      <div v-else-if="!printerId" class="w-full h-full flex flex-col items-center justify-center text-muted text-xl">
        <svg class="w-7 h-7 fill-current mb-2" viewBox="0 0 24 24"><path d="M13.75,10.19L14.38,10.32L18.55,12.4C19.25,12.63 19.71,13.32 19.65,14.06V14.19L19.65,14.32L18.75,20.44C18.69,20.87 18.5,21.27 18.15,21.55C17.84,21.85 17.43,22 17,22H10.12C9.63,22 9.18,21.82 8.85,21.47L2.86,15.5L3.76,14.5C4,14.25 4.38,14.11 4.74,14.13H5.03L9,15V4.5A2,2 0 0,1 11,2.5A2,2 0 0,1 13,4.5V10.19H13.75Z" /></svg>
        <div class="text-sm">No printer selected</div>
      </div>
      <Printer 
        v-else
        :key="printerId"
        :printerId="printerId"
      />
    </div>
  </div>
</template>
<script>
import PrinterList from './PrinterList.vue';
import Printer from '@/components/printer/Printer.vue';

export default {
  name: 'Printers',
  components: {
    PrinterList,
    Printer
  },
  props: {
    printerId: {
      type: String,
      default: null
    }
  },
  computed: {
    printers() {
      return this.$store.getters['organization/getPrinters'].length;
    }
  },
  methods: {
    showPrinter(printer) {
      if (this.printerId !== printer.id) this.$router.push(`/printers/${printer.id}`)
    }
  }
}
</script>