<template>
  <div class="flex h-full">
    <Categories :category="category" :filter="filter" :isAdmin="isAdmin" />
    <div class="flex-grow">
      <div
        class="px-4 h-16 border-b border-primary flex items-center justify-between"
      >
        <h5>Library</h5>
        <!-- <div v-if="isAdmin" class="flex items-center">
          <CreateObject />
          <CreateObjectFromGcode class="ml-2" />
        </div> -->
      </div>
      <div v-if="searching" class="p-4 pt-12 flex justify-center">
        <svg class="animate-spin h-8 w-8" viewBox="0 0 24 24">
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
      <div
        v-else-if="!hits.length"
        class="p-4 pt-12 flex flex-col items-center text-muted text-xl"
      >
        <svg class="w-12 h-12 fill-current" viewBox="0 0 24 24">
          <path
            d="M22.11 21.46L2.39 1.73L1.11 3L4.3 6.19L3.53 6.62C3.21 6.79 3 7.12 3 7.5V16.5C3 16.88 3.21 17.21 3.53 17.38L11.43 21.82C11.59 21.94 11.79 22 12 22S12.41 21.94 12.57 21.82L17.28 19.17L20.84 22.73L22.11 21.46M5 15.91V9.21L10.29 12.18L11 12.89V19.29L5 15.91M13 19.29V14.89L15.82 17.7L13 19.29M9 5.82L7.56 4.36L11.43 2.18C11.59 2.06 11.79 2 12 2S12.41 2.06 12.57 2.18L20.47 6.62C20.79 6.79 21 7.12 21 7.5V16.5C21 16.85 20.82 17.16 20.53 17.33L19 15.8V9.21L14.78 11.58L13.31 10.11L17.96 7.5L12 4.15L9 5.82Z"
          />
        </svg>
        <div>No objects found</div>
      </div>
      <div v-else-if="hits.length" class="p-4 flex flex-wrap">
        <ListObject
          v-for="object in hits"
          :key="object.id"
          :object="object"
          :isAdmin="isAdmin"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { db } from "@/plugins/firebase";
import Categories from "@/components/library/Categories.vue";
// import CreateObject from '@/components/library/CreateObject.vue';
// import CreateObjectFromGcode from '@/components/library/CreateObjectFromGcode.vue';
import ListObject from "@/components/library/ListObject.vue";

export default {
  name: "Library",
  components: {
    Categories,
    // CreateObject,
    // CreateObjectFromGcode,
    ListObject,
  },
  props: {
    category: {
      type: String,
      default: "",
    },
    filter: {
      type: String,
      default: "",
    },
    orderBy: {
      type: String,
      default: "updated",
    },
    sort: {
      type: String,
      default: "desc",
    },
  },
  data() {
    return {
      searching: true,
      hits: [],
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters["user/isAdmin"]();
    },
  },
  methods: {
    search() {
      this.searching = true;
      this.$store.commit("core/REMOVE_LISTENER", "search");
      this.hits.splice(0, this.hits.length);
      let query = db.collection(
        `organizations/${this.$store.state.organization.id}/objects`
      );
      // Filters
      if (this.filter.length && this.$store.getters["user/isAdmin"]()) {
        if (this.filter === "Deleted") {
          query = query.where("deleted", "==", true);
        } else if (this.filter === "Needs review") {
          query = query
            .where("deleted", "==", false)
            .where("generation.status.reviewed", "==", false)
            .where("generation.status.published", "==", false);
        } else if (this.filter === "Reviewed") {
          query = query
            .where("deleted", "==", false)
            .where("generation.status.reviewed", "==", true);
        } else if (this.filter === "Published") {
          query = query
            .where("deleted", "==", false)
            .where("generation.status.published", "==", true);
        }
      } else if (this.filter.length) {
        query = query
          .where("deleted", "==", false)
          .where("generation.status.published", "==", true);
      } else {
        query = query.where("deleted", "==", false);
      }
      // Categories
      if (this.category.length) {
        query = query.where("category", "==", this.category);
      }
      // Order by
      if (this.orderBy === "updated") {
        query = query.orderBy("updated.time", this.sort);
      } else if (this.orderBy === "name") {
        query = query.orderBy("name", this.sort);
      }
      query = query.limit(15);
      const listener = query.onSnapshot(
        (querySnap) => {
          querySnap.docChanges().forEach((change) => {
            const { type, doc, newIndex } = change;
            if (doc.id === "categories") return true;
            const index = this.hits.findIndex(({ id }) => id === doc.id);
            if (index > -1) this.hits.splice(index, 1);
            if (type !== "removed") {
              this.hits.splice(newIndex, 0, doc.data());
            }
          });
          this.searching = false;
        },
        (error) => {
          console.error(error);
          setTimeout(this.search(), 10000);
        }
      );
      this.$store.commit("core/ADD_LISTENER", { id: "search", listener });
    },
  },
  beforeDestroy() {
    this.$store.commit("core/REMOVE_LISTENER", "search");
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.search();
      },
    },
  },
};
</script>
