<template>
  <form 
    @submit.prevent="signIn"
  >
    <h3 class="mb-6">Sign in to your account</h3>
    <div class="mb-2">
      <label for="email">Email</label>
    </div>
    <input
      name="email"
      class="w-full p-4 mb-6"
      type="text"
      autocomplete="email"
      :class="[$v.email.$dirty && $v.email.$invalid ? 'border-2 border-error' : 'border border-primary', loading ? 'cursor-not-allowed' : '']"
      :disabled="loading"
      v-model.trim="$v.email.$model"
    />
    <div class="mb-2 flex justify-between">
      <label for="password">Password</label>
      <router-link
        to="/login/reset"
        class="text-secondary font-medium hover:cursor-pointer"
        v-text="'Forgot password?'"
      />
    </div>
    <input
      name="password"
      autocomplete="current-password"
      class="w-full p-4 rounded mb-6"
      type="password"
      v-model.trim="$v.password.$model"
      :class="[$v.password.$dirty && $v.password.$invalid ? 'border-2 border-error' : 'border border-primary', loading ? 'cursor-not-allowed' : '']"
      :disabled="loading"
    />
    <Button
      color="primary"
      type="wide"
      class="w-full"
      :loading="loading"
    >
      Continue
    </Button>
    <div v-if="error" class="text-error mt-4" v-text="error" />
  </form>
</template>
<script>
import { required, email, minLength } from 'vuelidate/lib/validators';
import Button from '@/components/base/Button.vue';
import { signInWithEmailAndPassword, errorDisplay } from '@/services/auth';
import { auth } from '@/plugins/firebase';

export default {
  name: 'LoginForm',
  components: {
    Button,
  },
  data() {
    return {
      email: '',
      password: '',
      error: null,
      loading: false,
    }
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required,
      minLength: minLength(6),
    },
  },
  methods: {
    signIn() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.loading = true;
        this.error = null;
        signInWithEmailAndPassword(this.email, this.password)
          .then(this.loginSuccess)
          .catch((error) => {
            console.error(error)
            this.error = errorDisplay(error);
          })
          .finally(() => {
            this.loading = false;
          })
      }
    },
    async loginSuccess() {
      const {
        query: {
          route,
          callback
        },
        path
      } = this.$router.currentRoute
      await new Promise(resolve => {
        const interval = setInterval(() => {
          if (this.$store.state.organization.id) {
            clearInterval(interval)
            resolve()
          }
        }, 100)
      })
      if (callback) {
        // If callback call callback with tokens
        const tokenResult = await auth.currentUser.getIdTokenResult(false)
        const authResponse = {
          organizationId: this.$store.state.organization.id,
          refreshToken: auth.currentUser.refreshToken,
          accessToken: tokenResult.token,
          expires: Date.parse(tokenResult.expirationTime)
        }
        
        window.location = `${callback}?${new URLSearchParams(authResponse).toString()}`;
      } else if (route && route !== path) {
        // If redirect specified a querystring r if not already there
        this.$router.push(route);
      } else {
        this.$router.push('/library');
      }
    }
  },
}
</script>