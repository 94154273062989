import { ApolloClient } from 'apollo-client'
import { WebSocketLink } from 'apollo-link-ws'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context';
import PrinterApiError from './printer-api-error'

class GraphQLClient {
  constructor (name, httpUri, wsUri, token) {

    // HTTP connection to the API
    const httpLink = new HttpLink({
      uri: httpUri
    })

    // Add auth headers if token is used
    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`
        }
      }
    });

    // Cache implementation
    const cache = new InMemoryCache()

    // Create the apollo clients
    this.httpClient = new ApolloClient({
      link: authLink.concat(httpLink),
      credentials: 'include',
      cache,
      name: name
    })

    // WS connection to the API, optional
    if (wsUri) {
      const wsLink = new WebSocketLink({
        uri: wsUri,
        options: {
          reconnect: true
        }
      })

      this.wsClient = new ApolloClient({
        link: authLink.concat(wsLink),
        cache,
        name: name,
        options: {
          reconnect: true
        }
      })
    }

    this.subscriptions = []
  }

  async disconnect () {
    for (var i = 0; i < this.subscriptions.length; i++) {
      this.subscriptions[i].unsubscribe()
    }

    await this.httpClient.clearStore()
    if (this.wsClient) {
      await this.wsClient.clearStore()
      this.wsClient.stop()
    }
  }

  async query (gql, fetchPolicy = 'no-cache') {
    const result = await this.httpClient.query({ query: gql, fetchPolicy: fetchPolicy })
      .catch(error => this.handleErrors(error))

    return result.data
  }

  async mutate (gql) {
    const result = await this.httpClient.mutate({ mutation: gql })
      .catch(error => this.handleErrors(error))

    return result
  }

  async subscribe (gql, onDataReceived, onError, fetchPolicy = 'no-cache') {
    if (!this.wsClient) throw new PrinterApiError('No WS uri specified')
    const observable = this.wsClient.subscribe({ query: gql, fetchPolicy: fetchPolicy })

    const subscription = observable.subscribe({
      next: (data) => {
        console.log(`[${(new Date()).toISOString()}]: Received subscription data: ${JSON.stringify(data.data, null, 2)}`)
        onDataReceived(data.data)
      },
      error: (error) => {
        this.handleErrors(error).catch(error => { onError(error) })
      }
    })

    this.subscriptions.push(subscription)
  }

  // Internal method
  async handleErrors (error) {
    const networkError = error?.networkError
    if (networkError != null) {
      throw new PrinterApiError(networkError.message, 'NETWORK')
    }

    const graphQLErrors = error?.graphQLErrors
    if (graphQLErrors === undefined || graphQLErrors.length === 0) throw error

    const primaryError = graphQLErrors[0]
    const errorMessage = primaryError.message

    if (primaryError?.extensions?.code === 'API_GENERIC_ERROR') {
      throw new PrinterApiError(errorMessage, 'USER')
    } else {
      throw new PrinterApiError(errorMessage, 'INTERNAL')
    }
  }
}

export default GraphQLClient
