import GraphQLClient from './graphql-client'
import gql from 'graphql-tag'

class PrinterAPIClient {
	constructor (clientName, httpEndpoint, wsEndpoint, token) {
		this.graphQL = new GraphQLClient(clientName, httpEndpoint, wsEndpoint, token)
		this.disconnected = false
	}

	async disconnect() {
		await this.graphQL.disconnect()
		this.disconnected = true
	}

	// *******************************************
	//                  QUERYING
	// *******************************************
	fetchDataPeriodically(dataSchema, interval, onDataFetch, onError = () => { }) {
		const fetchData = async (self) => {
			if (self == null) return
			if (self.disconnected) return

			try {
				const data = await self.graphQL.query(gql`
					query PeriodicFetchQuery {
						${dataSchema}
					}
				`)

				onDataFetch(data)
			} catch (error) {
				onError(error)
			} finally {
				setTimeout(async () => fetchData(self), interval)
			}
		}

		fetchData(this)
	}

	// *******************************************
	//          PREDEFINED SUBSCRIPTIONS
	// *******************************************
	async subscribePrinterMetaChange(onReceive, onError) {
		await this.graphQL.subscribe(gql`
			subscription {
				printerMetaChange {
					name
					location
					organizationId
					releaseId
					releaseChanged
					uiNeedsRefresh
				}
			}
		`, onReceive, onError)
	}

	async subscribePrinterStateChange(onReceive, onError) {
		await this.graphQL.subscribe(gql`
			subscription {
				printerStateChange {
					clearedBuildPlate
					materials {
						trayA {
							id,
							sku
						}
						trayB {
							id,
							sku
						}
					}
					doorsLocked
					printerStatus
					emergencyStopStatus
					emergencyStopReason
					remotePrintingStatus
					chamberTemperature
					axesHomed
					lightsOn
					raisedAlarms {
						code
						severity
						raisedTime
					},
					heightMapDate
					newFilamentDetected
				}
			}
		`, onReceive, onError)
	}

	async subscribeQueueMetaChange(onReceive, onError) {
		await this.graphQL.subscribe(gql`
			subscription {
				queueMetaChange {
					jobCount
					runCount
					printTime
				}
			}
		`, onReceive, onError)
	}

	async subscribeQueueAdd(onReceive, onError) {
		await this.graphQL.subscribe(gql`
			subscription {
				queueAdd
			}
		`, onReceive, onError)
	}

	async subscribeQueueRemove(onReceive, onError) {
		await this.graphQL.subscribe(gql`
			subscription {
				queueRemove
			}
		`, onReceive, onError)
	}

	async subscribeQueueUpdate(onReceive, onError) {
		await this.graphQL.subscribe(gql`
			subscription {
				queueUpdate
			}
		`, onReceive, onError)
	}

	async subscribeQueueClear(onReceive, onError) {
		await this.graphQL.subscribe(gql`
			subscription {
				queueClear
			}
		`, onReceive, onError)
	}

	async subscribeActiveJobChange(onReceive, onError) {
		await this.graphQL.subscribe(gql`
			subscription {
				activeJobChange
			}
		`, onReceive, onError)
	}

	async subscribeActiveRunChange(onReceive, onError) {
		await this.graphQL.subscribe(gql`
			subscription {
				activeRunChange
			}
		`, onReceive, onError)
	}

	async subscribeLogAdd(onReceive, onError) {
		await this.graphQL.subscribe(gql`
			subscription {
				logAdd {
					date
					level
					category
					message
				}
			}
		`, onReceive, onError)
	}

	async subscribeFilamentChangeStatus(onReceive, onError) {
		await this.graphQL.subscribe(gql`
			subscription {
				filamentChangeUpdate {
					tray
					step
					progress
					completed
				}
			}
		`, onReceive, onError)
	}

	// *******************************************
	//             PREDEFINED MUTATIONS
	// *******************************************
	setPrinterName(name) {
		return this.graphQL.mutate(gql`
				mutation UpdatePrinterName {
				Commands {
					Meta {
						updateName(name: "${name}")
					}
				}
			}
		`)
	}

	acknowledgeReleaseChange() {
		return this.graphQL.mutate(gql`
		  mutation acknowledgeReleaseChanged {
		  Commands {
			Meta {
				acknowledgeReleaseChange
			  }
			}
		  }
		`);
	}

	uiDidRefresh() {
		return this.graphQL.mutate(gql`
		  mutation uiDidRefresh {
		  Commands {
			Meta {
				uiDidRefresh
			  }
			}
		  }
		`);
	}


	setPrinterLocation(location) {
		return this.graphQL.mutate(gql`
				mutation UpdatePrinterLocation {
				Commands {
					Meta {
						updateLocation(location: "${location}")
					}
				}
			}
		`)
	}

	setPrinterOrganizationId(organizationId) {
		return this.graphQL.mutate(gql`
				mutation UpdatePrinterOrganizationId {
				Commands {
					Meta {
						updateOrganizationId(organizationId: "${organizationId}")
					}
				}
			}
		`)
	}
	startPrint() {
		return this.graphQL.mutate(gql`
				mutation PrintStart {
				Commands {
					Print {
						start
					}
				}
			}
		`)
	}

	resumePrint() {
		return this.graphQL.mutate(gql`
				mutation PrintResume {
				Commands {
					Print {
						resume
					}
				}
			}
		`)
	}

	pausePrint() {
		return this.graphQL.mutate(gql`
				mutation PrintPause {
				Commands {
					Print {
						pause
					}
				}
			}
		`)
	}

	abortPrint() {
		return this.graphQL.mutate(gql`
				mutation PrintAbort {
				Commands {
					Print {
						abort
					}
				}
			}
		`)
	}

	setPrinterBedCleared() {
		return this.graphQL.mutate(gql`
				mutation PrinterBedSetCleared {
				Commands {
					Bed {
						cleared
					}
				}
			}
		`)
	}

	unlockDoors (shouldParkHead) {
		return this.graphQL.mutate(gql`
				mutation DoorUnlock {
				Commands {
					Doors {
						unlock(shouldParkHead: ${shouldParkHead})
					}
				}
			}
		`)
	}

  	resetHeaterFault (heater) {
	    return this.graphQL.mutate(gql`
			mutation ResetHeaterFault {
			Commands {
				Advanced {
					resetHeaterFault(heater: ${heater})
					}
				}
			}
	    `)
	}

	resetAllHeaterFaults () {
		return this.graphQL.mutate(gql`
				mutation ResetAllHeaterFaults {
				Commands {
					Advanced {
						resetAllHeaterFaults
					}
				}
			}
		`)
	}
	
	queueAdd (jobData) {
		const str = this.stringifyObject(jobData)

		return this.graphQL.mutate(gql`
				mutation QueueAdd {
				Commands {
					Queue {
						add(job: ${str})
					}
				}
			}
		`)
	}

	queueRemove(jobId) {
		return this.graphQL.mutate(gql`
				mutation QueueRemove {
				Commands {
					Queue {
						remove(id: "${jobId}")
					}
				}
			}
		`)
	}

	pushQueuedJobToActive(jobId) {
		return this.graphQL.mutate(gql`
				mutation ActiveJobPush {
				Commands {
					ActiveJob {
						setActive(id: "${jobId}")
					}
				}
			}
		`)
	}

	moveActiveJobToQueue() {
		return this.graphQL.mutate(gql`
				mutation ActiveJobMoveToQueue {
				Commands {
					ActiveJob {
						moveToQueue
					}
				}
			}
		`)
	}

	clearActiveJob() {
		return this.graphQL.mutate(gql`
				mutation ActiveJobClear {
				Commands {
					ActiveJob {
						clear
					}
				}
			}
		`)
	}

	filamentLoadBegin(tray, materialId, sku) {
		return this.graphQL.mutate(gql`
				mutation FilamentLoadBegin {
				Commands {
					Filament {
						loadBegin(tray: ${tray}, materialId: "${materialId}", sku: "${sku}")
					}
				}
			}
		`)
	}

	filamentLoadStart() {
		return this.graphQL.mutate(gql`
				mutation FilamentLoadStart {
				Commands {
					Filament {
						loadStartMove
					}
				}
			}
		`)
	}

	filamentLoadSetFinished(finished) {
		return this.graphQL.mutate(gql`
				mutation FilamentLoadSetFinished {
				Commands {
					Filament {
						loadSetFinished(finished: ${finished})
					}
				}
			}
		`)
	}

	filamentUnloadStart(tray) {
		return this.graphQL.mutate(gql`
				mutation FilamentUnloadBegin {
				Commands {
					Filament {
						unloadBegin(tray: ${tray})
					}
				}
			}
		`)
	}

	filamentUnloadSetFinished() {
		return this.graphQL.mutate(gql`
				mutation FilamentUnloadSetFinished {
				Commands {
					Filament {
						unloadFinished
					}
				}
			}
		`)
	}

	filamentManualOverride(tray, materialId, sku) {
		return this.graphQL.mutate(gql`
				mutation FilamentUnloadSetFinished {
				Commands {
					Advanced {
						materialOverride(tray: ${tray}, materialId: "${materialId}", sku: "${sku}")
					}
				}
			}
		`)
	}

	emergencyStopReset() {
		return this.graphQL.mutate(gql`
				mutation EmergencyStopReset {
				Commands {
					EmergencyStop {
						resetMachine
					}
				}
			}
		`)
	}

	turnOnPrinterLights() {
		return this.graphQL.mutate(gql`
				mutation LightsOn {
				Commands {
					Lights {
						turnOn
					}
				}
			}
		`)
	}

	turnOffPrinterLights() {
		return this.graphQL.mutate(gql`
				mutation LightsOff {
				Commands {
					Lights {
						turnOff
					}
				}
			}
		`)
	}

	probePrintBed() {
		return this.graphQL.mutate(gql`
				mutation ProbePrintBed {
				Commands {
					Advanced {
						probePrintBed
					}
				}
			}
		`)
	}

	probePrintBedMesh() {
		return this.graphQL.mutate(gql`
				mutation ProbePrintBedMesh {
				Commands {
					Advanced {
						probePrintBedMesh
					}
				}
			}
		`)
	}

	homeAxes(force) {
		return this.graphQL.mutate(gql`
				mutation ProbePrintBed {
				Commands {
					Advanced {
						homeAxes(force: ${force})
					}
				}
			}
		`)
	}

	sendDiagnosticReport() {
		return this.graphQL.mutate(gql`
				mutation DiagnosticReport {
				Commands {
					Advanced {
						diagnosticsReport
					}
				}
			}
		`)
	}

	setZOffset(tool, value) {
		return this.graphQL.mutate(gql`
			mutation SetZOffset {
				Commands {
					Advanced {
						setZOffset(tool: ${tool}, value: ${value})
					}
				}
			}
		`)
	}

	initZOffsetCalibration(tool) {
		return this.graphQL.mutate(gql`
			mutation InitZOffsetCalibration {
				Commands {
					Advanced {
						initZOffsetCalibration(tool: ${tool})
					}
				}
			}
		`)
	}

	storeParameters() {
		return this.graphQL.mutate(gql`
			mutation StoreParameters {
				Commands {
					Advanced {
						storeParameters
					}
				}
			}
		`)
	}

	moveZAxisToRelativePosition(position) {
		return this.graphQL.mutate(gql`
			mutation moveZAxisToRelativePosition {
				Commands {
					Advanced {
						moveZAxisToRelativePosition(position: ${position})
					}
				}
			}
		`)
	}

	moveZAxisToAbsolutePosition(position) {
		return this.graphQL.mutate(gql`
			mutation moveZAxisToAbsolutePosition {
				Commands {
					Advanced {
						moveZAxisToAbsolutePosition(position: ${position})
					}
				}
			}
		`)
	}
	
	allowRemotePrint () {
		return this.graphQL.mutate(gql`
			mutation allowRemotePrint {
				Commands {
					Advanced {
						allowRemotePrint
					}
				}
			}
			`)
		}
	
		cancelRemotePrint () {
			return this.graphQL.mutate(gql`
				mutation cancelRemotePrint {
					Commands {
						Advanced {
							cancelRemotePrint
						}
					}
				}
			`)
		}
	
		requestRemotePrint () {
			return this.graphQL.mutate(gql`
				mutation requestRemotePrint {
					Commands {
						Advanced {
							requestRemotePrint
						}
					}
				}
			`)
		}

	// *******************************************
	//             INTERNAL METHODS
	// *******************************************
	stringifyObject(objFromJson) {
		if (typeof objFromJson !== 'object' || objFromJson === null || objFromJson === undefined) {
			// Not an object, stringify using native function
			return JSON.stringify(objFromJson)
		}

		if (Array.isArray(objFromJson)) {
			// Is array, stringify
			let arrayStr = '[\n'
			objFromJson.forEach(x => { arrayStr += this.stringifyObject(x) })
			arrayStr += '\n]'

			return arrayStr
		}

		// Implements recursive object serialization according to JSON spec
		// but without quotes around the keys.
		const props = Object
			.keys(objFromJson)
			.map(key => `${key}:${this.stringifyObject(objFromJson[key])}`)
			.join('\n')

		return `{\n${props}\n}`
	}
}

export default PrinterAPIClient
