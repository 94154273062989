<template>
  <div class="w-full h-full flex flex-col">
    <Toolbar class="px-4 py-2 border-b border-primary flex-shrink-0" />
    <router-view class="flex-grow" />
    <portal-target name="notifications" />
    <Notifications />
  </div>
</template>

<script>
import Toolbar from '@/components/other/Toolbar.vue';
import Notifications from '@/components/other/Notifications.vue';

export default {
  name: "Layout",
  components: {
    Toolbar,
    Notifications,
  },
};
</script>