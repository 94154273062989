import Vue from 'vue';

// Lists
const ADD_TO_LIST = (state, { key, value }) => state[key].push(value);

const REMOVE_FROM_LIST = (state, { key, id }) => {
  const index = state[key].findIndex((i) => i.id === id);
  if(index > -1) state[key].splice(index, 1);
}

const SET_LIST = (state, { key, values }) => state[key] = [...values];

const CLEAR_LIST = (state, key) => state[key] = [];

// Objects
const SET_DATA = (state, data) => {
  for(let key in data) {
    if(Array.isArray(data[key])) {
      state[key] = [...data[key]];
    } else {
      Vue.set(state, key, data[key])
    }
  }
};

export {
  ADD_TO_LIST,
  REMOVE_FROM_LIST,
  SET_LIST,
  CLEAR_LIST,
  SET_DATA,
}