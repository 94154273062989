<template>
  <div class="relative inline-block text-left">
    <div v-if="open" @click="toggle" class="fixed inset-0 cursor-auto" />
    <div @click.stop.prevent="toggle">
      <slot name="activator" />
    </div>
    <transition
      enter-active-class="transition ease-out duration-100"
      leave-active-class="transition ease-in duration-75"
      enter-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        v-if="open"
        :class="[origin, position[origin]]"
        class="absolute flex w-auto mt-2 rounded-md shadow-lg z-10 bg-white shadow-xs flex-shrink-0"
      >
        <div class="py-1" role="menu" @click="away">
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: 'ProfileAvatar',
  props: {
    origin: {
      type: String,
      default: 'origin-top-right'
    },
  },
  data() {
    return {
      open: false,
      position: {
        'origin-top-right': 'right-0',
        'origin-top-left': 'left-0',
      }
    }
  },
  methods: {
    toggle () {
      this.open = !this.open
    },
    away () {
      this.open = false
    }
  }
}
</script>