<template>
  <div class="py-8 px-28">
    <h1 class="border-b border-primary pb-4 mb-8">Restock materials</h1>
    <div class="rounded-lg border border-primary p-8">
      <div class="font-bold">Coming...</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Restock',
}
</script>