<template>
  <router-link
    class="list-object hover:shadow-md"
    :to="`/library/${object.id}`"
  >
    <div class="image-container relative bg-light flex justify-center items-center">
      <div class="flex justify-center items-center p-4" style="height: 133px; width: 228px">
        <img v-if="object.generation && object.generation.thumbnail" :src="object.generation.thumbnail" class="object-contain" style="height: 104px; width: 199px" />
        <img v-else src="/images/placeholder.png" class="w-1/2" />
      </div>
      <div
        v-if="materials.length"
        class="absolute left-0 materials bg-white rounded-r-md text-xs text-muted p-1 border-r border-primary font-bold hover:font-normal"
      >
        <div
          v-for="(material, index) in materials"
          :key="`${object.id}material${index}`"
          v-text="material"
          class="truncate pr-2"
        />
      </div>
      <div class="absolute right-0 print-time bg-white rounded-l-md border-l border-primary text-xs p-1 text-right">
        <div class="text-muted" v-text="'Print time'" />
        <div class="font-medium">
          {{ estPrintTime | formatDuration({ short: true }) }}
        </div>
      </div>
      <div class="absolute menu top-0 pr-1 pt-2 right-0">
        <div class="relative" @click.stop.prevent>
          <Dropdown origin="origin-top-right">
            <template #activator>
              <svg class="h-6 w-6 hover:cursor-pointer" viewBox="0 0 24 24">
                <path d="M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z" />
              </svg>
            </template>
            <template>
              <ul @click.stop.prevent style="width:160px">
                <li v-if="readyToPrint">
                  <ObjectQueue
                    :object="object"
                    :generation="object.generation"
                    :readyToPrint="object.printable"
                  >
                    <template #activator>
                      <div class="flex items-center hover:cursor-pointer">
                        <svg class="h-5 w-5 mr-2 fill-current text-primary" viewBox="0 0 24 24">
                          <path d="M19,6A1,1 0 0,0 20,5A1,1 0 0,0 19,4A1,1 0 0,0 18,5A1,1 0 0,0 19,6M19,2A3,3 0 0,1 22,5V11H18V7H6V11H2V5A3,3 0 0,1 5,2H19M18,18.25C18,18.63 17.79,18.96 17.47,19.13L12.57,21.82C12.4,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L6.53,19.13C6.21,18.96 6,18.63 6,18.25V13C6,12.62 6.21,12.29 6.53,12.12L11.43,9.68C11.59,9.56 11.79,9.5 12,9.5C12.21,9.5 12.4,9.56 12.57,9.68L17.47,12.12C17.79,12.29 18,12.62 18,13V18.25M12,11.65L9.04,13L12,14.6L14.96,13L12,11.65M8,17.66L11,19.29V16.33L8,14.71V17.66M16,17.66V14.71L13,16.33V19.29L16,17.66Z" />
                        </svg>
                        Queue
                      </div>
                    </template>
                  </ObjectQueue>
                </li>
                <li v-if="isAdmin">
                  <ChangeCategory
                    :category="object.category"
                    :objectId="object.id"
                  >
                    <template #activator>
                      <div class="flex items-center hover:cursor-pointer">
                        <svg class="h-5 w-5 mr-2 fill-current text-info" viewBox="0 0 24 24">
                          <path d="M14,18V15H10V11H14V8L19,13M20,6H12L10,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V8C22,6.89 21.1,6 20,6Z" />
                        </svg>
                        Change category
                      </div>
                    </template>
                  </ChangeCategory>
                </li>
                <li v-if="isAdmin && !object.deleted">
                  <div
                    @click.self="remove"
                    class="flex items-center hover:cursor-pointer"
                  >
                    <svg v-if="deleting" class="animate-spin mr-2 h-5 w-5" viewBox="0 0 24 24">
                      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    <svg v-else class="h-5 w-5 mr-2 fill-current text-error" viewBox="0 0 24 24">
                      <path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
                    </svg>
                    Delete
                  </div>
                </li>
                <li v-if="isAdmin && object.deleted">
                  <div
                    @click.stop.prevent="restore"
                    class="flex items-center hover:cursor-pointer"
                  >
                    <svg v-if="restoring" class="animate-spin text-primary mr-2 h-5 w-5" viewBox="0 0 24 24">
                      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    <svg v-else class="h-5 w-5 mr-2 fill-current text-warning" viewBox="0 0 24 24">
                      <path d="M12,3A9,9 0 0,0 3,12H0L4,16L8,12H5A7,7 0 0,1 12,5A7,7 0 0,1 19,12A7,7 0 0,1 12,19C10.5,19 9.09,18.5 7.94,17.7L6.5,19.14C8.04,20.3 9.94,21 12,21A9,9 0 0,0 21,12A9,9 0 0,0 12,3M14,12A2,2 0 0,0 12,10A2,2 0 0,0 10,12A2,2 0 0,0 12,14A2,2 0 0,0 14,12Z" />
                    </svg>
                    Restore
                  </div>
                </li>
              </ul>
            </template>
          </Dropdown>
        </div>
      </div>
    </div>
    <div class="p-2 flex flex-grow flex-col justify-between">
      <div class="flex mb-2 h-5 items-center justify-between">
        <div>
          <div
            v-if="object.category"
            class="chip chip-info"
            v-text="object.category"
          />
        </div>
        <svg v-if="isAdmin" class="h-5 w-5 fill-current" :class="displayStatus.color" viewBox="0 0 24 24">
          <path :d="displayStatus.path" />
        </svg>
      </div>
      <div class="font-medium text-md truncate" :title="object.name" v-text="object.name" />
      <div class="h-5">
        <div class="truncate h-5" v-if="object.generation" :title="object.generation.description" v-text="object.generation.description || ''" />
      </div>
      <div class="flex justify-between items-end text-xs text-muted" v-if="isAdmin">
        <div class="truncate" :title="createdBy" v-text="createdBy" />
        <div class="flex-shrink-0 flex">
          <div>{{ object.updated.time | formatTimestamp('d MMM yyyy HH:mm') }}</div>
          <div v-if="object.generation" v-text="`, v${object.generation.version}`" />
        </div>
      </div>
    </div>
  </router-link>
</template>
<script>
import { objectStatuses, objectStatus } from '@/services/formatting'
import { callable } from '@/plugins/firebase';
import Dropdown from '@/components/base/Dropdown.vue';
import ChangeCategory from '@/components/objects/ChangeCategory.vue';
import ObjectQueue from '@/components/objects/ObjectQueue';


export default {
  name: 'ListObject',
  components: {
    Dropdown,
    ChangeCategory,
    ObjectQueue
  },
  props: {
    object: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      objectStatuses,
      deleting: false,
      restoring: false,
      moving: false,
      category: this.object.category,
    }
  },
  computed: {
    displayStatus () {
      return objectStatus(this.object, this.object.generation)
    },
    createdBy () {
      return this.$store.getters['organization/getUserName'](this.object.created.uid)
    },
    materials () {
      const materials = []
      if (this.object.generation && this.object.generation.materials.trayA.id) {
        materials.push(this.$store.getters['core/findMaterialById'](this.object.generation.materials.trayA.id))  
      }
      if (this.object.generation && this.object.generation.materials.trayB.id) {
        materials.push(this.$store.getters['core/findMaterialById'](this.object.generation.materials.trayB.id))  
      }
      return materials.filter(i => Boolean(i))
    },
    generation() {
      if (!this.object.generation) return null
      return this.object.generation
    },
    multiplier() {
      if (
        !this.generation ||
        !this.generation.multipliers ||
        !this.generation.multipliers[1]
      ) return null;
      return this.generation.multipliers[1];
    },
    estPrintTime() {
      if(
        !this.multiplier ||
        !this.multiplier.calculations || 
        !this.multiplier.calculations.estPrintTime
      ) return null;
      return this.multiplier.calculations.estPrintTime
    },
    readyToPrint () {
      if (this.object.deleted || !this.multiplier) return false;
      return this.multiplier.stl.exists === true
    },
  },
  methods: {
    remove() {
      this.deleting = true;
      callable('objects-delete', {
        organizationId: this.$store.state.organization.id,
        objectId: this.object.id
      })
      .catch(err => {console.error(err)})
      .finally(() => {
        this.deleting = false;
      })
    },
    restore() {
      this.restoring = true;
      callable('objects-restore', {
        organizationId: this.$store.state.organization.id,
        objectId: this.object.id
      })
      .finally(() => {
        this.restoring = false;
      })
    },
  },
}
</script>
<style scoped>
.list-object {
  width: 230px;
  height: 230px;
}
.list-object {
  @apply border;
  @apply border-primary;
  @apply rounded;
  @apply shadow;
  @apply m-4;
  @apply flex;
  @apply flex-col;
  @apply font-normal;
  @apply flex-shrink-0;
}
.list-object .image-container {
  @apply w-full;
  @apply h-full;
}
.list-object .materials {
  bottom: 10px;
  width: 18px;
}
.list-object .materials:hover {
  width: auto;
}
.list-object .print-time {
  bottom: 10px;
}
.list-object .menu li > * {
  @apply px-4;
  @apply py-2;
  @apply font-bold;
}
.list-object .menu li:hover > * {
  @apply bg-light;
}
</style>