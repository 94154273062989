<template>
  <div>
    <div @click="open">
      <slot name="activator" />
    </div>
    <Modal
      :open="printModal"
      @close="printModal = false"
    >
      <div class="relative bg-white rounded-lg shadow-2xl px-8 py-6">
        <div v-if="queuing" class="absolute inset-0 flex items-center justify-center bg-white opacity-50">
          <svg class="animate-spin h-8 w-8" viewBox="0 0 24 24">
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
        <h2
          class="border-b border-primary pb-3 mb-5"
          v-text="'Quantity'"
        />
        <div class="mb-12 flex items-center space-x-4">
          <button class="" @click="decrease">
            <svg class="h-6 w-6 fill-current" viewBox="0 0 24 24">
              <path d="M19,13H5V11H19V13Z"></path>
            </svg>
          </button>
          <div class="text-lg" v-text="quantity" />
          <button class="" @click="increase">
            <svg class="h-6 w-6 fill-current" viewBox="0 0 24 24">
              <path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"></path>
            </svg>
          </button>
        </div>
        <h2
          class="border-b border-primary pb-3 mb-5"
          v-text="'Select printer'"
        />
        <PrinterList
          class="flex-grow-0 p-4"
          :showMaterials=true
          v-on:printerSelect="queue($event)"
        />
        <div class="flex justify-end mt-6">
          <Button
            @click="printModal = false"
            color="error"
            :disabled="queuing"
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import PrinterList from '@/components/printers/PrinterList.vue';
import Modal from '@/components/base/Modal.vue';
import Button from '@/components/base/Button.vue';
import { PrinterAPIClient } from '@/libs/printer-client'

export default {
  name: 'ObjectQueue',
  components: {
    Modal,
    Button,
    PrinterList,
  },
  props: {
    object: {
      type: Object,
      required: true,
    },
    generation: {
      type: Object,
      required: true,
    },
    readyToPrint: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      printModal: false,
      queuing: false,
      quantity: 1
    }
  },
  methods: {
    open() {
      this.printModal = true;
    },
    decrease () {
      if (this.quantity > 1) this.quantity -= 1
    },
    increase () {
      this.quantity += 1
    },
    async queue(printer) {
      this.queuing = true;
      const publicUrl = this.$store.getters['organization/getPrinter'](printer.id).publicUrl
      const httpUri = `https://${publicUrl}/graphql`;
      // const wsUri = `wss://${publicUrl}/graphql`;
      try {
        const client = new PrinterAPIClient('web-ui', httpUri, null, this.$store.state.user.token)
        await client.queueAdd({
          objectId: this.object.id,
          objectName: this.object.name,
          objectThumbnail: this.generation.thumbnail,
          generationId: this.generation.id,
          multipliers: [{
            estPrintTime: this.generation.multipliers[1].calculations.estPrintTime,
            quantity: this.quantity
          }],
          quantity: this.quantity,
          materials: {
            trayA: this.generation.materials.trayA.id,
            trayB: this.generation.materials.trayB.id
          }
        })
        this.$router.push(`/printers/${printer.id}`)
      } catch (error) {
        console.error(error)
      } finally {
        this.queuing = false;
      }
    }
  },
  watch: {
    readyToPrint(ready) {
      if (!ready) this.printModal = false;
    },
  },
}
</script>
