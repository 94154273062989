import Vue from 'vue';
import Vuex from 'vuex';
import core from './modules/core';
import user from './modules/user';
import organization from './modules/organization';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    core,
    user,
    organization,
  },
  strict: process.env.NODE_ENV !== 'production',
});

export default store;
