<template>
  <div>
    <div class="flex items-center">
      <Button
        @click="open = true"
        :disabled="!adminRights"
      >
        <svg
          class="cursor-pointer fill-current w-6 h-6"
          viewBox="0 0 24 24">
          <path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
        </svg>
        <div>Add user</div>
      </Button>
      <Info v-if="!adminRights" class="ml-2" :size=6>
        Only admins can add users
      </Info>
    </div>
    <Modal
      :open="open"
      @close="open = false"
      :loading="loading"
    >
      <div class="w-full bg-white rounded-lg shadow-2xl px-8 py-6">
        <h2
          class="border-b border-primary pb-3 mb-5"
          v-text="'Add user'"
        />
        <div class="w-74 mb-6">
          If user does not have a previous account, a sign up email will be sent to the email.
        </div>
        <input
          class="w-full block appearance-none py-3 px-2 rounded-md leading-tight focus:outline-none"
          type="text"
          v-model.trim="$v.email.$model"
          :class="[
            $v.email.$dirty && $v.email.$invalid ? 'border-2 border-error' : 'border-2 border-primary',
            loading ? 'cursor-not-allowed' : ''
          ]"
          :disabled="loading"
          placeholder="Email"
        />
        <div class="flex mt-6 mb-4 items-center justify-between">
          <div class="mr-4 font-medium">Role</div>
          <Dropdown origin="origin-top-right">
            <template #activator>
              <Button
                color="primary"
                type="tight"
                class="text-xs tracking-wide leading-tight"
              >
                {{ role | capitalize }}
                <svg class="-mr-1 ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </button>
            </template>
            <template>
              <ul class="block w-full">
                <li
                  v-for="newRole in roles"
                  :key="newRole"
                  class="w-full"
                >
                  <button
                    class="w-full text-left py-2 font-bold px-4 hover:bg-light hover:cursor-pointer"
                    @click="role = newRole"
                  >
                    {{ newRole | capitalize }}
                  </button>
                </li>
              </ul>
            </template>
          </Dropdown>
        </div>
        <div class="flex mt-4 mb-6 items-center justify-between">
          <div class="mr-4 font-medium">Resource</div>
          <Dropdown origin="origin-top-right">
            <template #activator>
              <Button
                color="primary"
                type="tight"
                class="text-xs tracking-wide leading-tight"
              >
                {{ resource.text }}
                <svg class="-mr-1 ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </button>
            </template>
            <template>
              <ul class="block w-full">
                <li>
                  <button
                    class="w-full text-left py-2 font-bold px-4 hover:bg-light hover:cursor-pointer"
                    @click="resource = { text: 'Global', value: 'global'}"
                  >
                    Global
                  </button>
                </li>
                <li
                  v-for="department in $store.state.organization.departments"
                  :key="department.id"
                  class="w-full"
                >
                  <button
                    class="w-full text-left py-2 font-bold px-4 hover:bg-light hover:cursor-pointer"
                    @click="resource = { text: department.name, value: department.id}"
                  >
                    {{ department.name }}
                  </button>
                </li>
              </ul>
            </template>
          </Dropdown>
        </div>
        <Button
          type="wide"
          class="w-full"
          :class="{'cursor-not-allowed': $v.email.$invalid}"
          :disabled="$v.email.$invalid || loading"
          @click="!$v.email.$invalid && !loading ? create() : null"
        >
          Add user
        </button>
        <div class="mt-2 text-error" v-text="error" />
      </div>
    </Modal>
  </div>
</template>
<script>
import { required, email } from 'vuelidate/lib/validators';
import Modal from '@/components/base/Modal.vue';
import Dropdown from '@/components/base/Dropdown.vue';
import Button from '@/components/base/Button.vue';
import Info from '@/components/base/Info.vue';
import { callable } from '@/plugins/firebase';

export default {
  name: 'UserAdd',
  components: {
    Modal,
    Dropdown,
    Button,
    Info
  },
  data() {
    return {
      open: false,
      email: '',
      error: null,
      role: 'user',
      resource: {
        text: 'All',
        value: 'global'
      },
      roles: ['user','admin'],
      loading: false,
    }
  },
  validations: {
    email: {
      required,
      email
    },
  },
  computed: {
    adminRights() {
      return this.$store.getters['user/isAdmin']();
    },
  },
  methods: {
    create() {
      if(!this.$v.$invalid) {
        this.loading = true;
        this.error = null;
        callable('users-create', {
          organizationId: this.$store.state.organization.id,
          claim: this.resource.value,
          role: this.role,
          email: this.email
        })
        .then(() => { this.open = false; this.$emit('refresh-claims'); })
        .catch((error) => { console.error(error); this.error = error.message; })
        .finally(() => { this.loading = false; })
      }
    }
  },
}
</script>