<template>
  <div class="flex flex-table">
    <div class="mr-24 font-bold">
      <div class="h-6 flex items-center">
        <div v-text="'Organization'" />
      </div>
      <div class="h-6 flex items-center">
        <div v-text="'Allowed domains'" />
        <Info class="ml-2" :size=4>
          Only allow users to be added with emails in the allowed domains list
        </Info>
      </div>
    </div>
    <div>
      <div class="h-6 flex items-center">
        <div v-text="$store.state.organization.name" />
      </div>
      <div class="h-6 flex items-center">
        <div v-text="$store.state.organization.allowedDomains.join(', ')" />
      </div>
    </div>
  </div>
</template>
<script>
import Info from '@/components/base/Info.vue';

export default {
  name: 'Organization',
  components: {
    Info
  }
}
</script>