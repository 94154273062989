<template>
  <Dropdown origin="origin-top-right">
    <template #activator>
      <Button
        color="error"
        type="tight"
        :loading="loading"
      >
        Delete
      </Button>
    </template>
    <template>
      <div class="text-primary p-6 w-74 relative">
        <h4>Are you sure?</h4>
        <p class="my-4">Warning: This will remove the user from this organization!</p>
        <div class="flex space-x-6">
          <Button
            color="error"
            type="tight"
            @click="removeUser"
          >
            Delete
          </Button>
          <Button
            color="outline"
            type="tight"
          >
            Cancel
          </Button>
        </div>
      </div>
    </template>
  </Dropdown>
</template>
<script>
import Dropdown from '@/components/base/Dropdown.vue';
import Button from '@/components/base/Button.vue';
import { callable } from '@/plugins/firebase';
export default {
  name: 'UserRemove',
  components: {
    Dropdown,
    Button,
  },
  props: {
    claim: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    removeUser() {
      this.loading = true;
        callable('users-remove', {
          organizationId: this.$store.state.organization.id,
          claimId: this.claim.id,
          email: this.claim.email
        })
        .then(() => { this.$emit('refresh-claims'); })
        .finally(() => { this.loading = false; });
    },
  }
}
</script>