<template>
  <div class="p-4 flex flex-col">
    <div class="flex justify-center mb-4 flex-shrink-0" style="height: 200px;">
      <div class="flex border border-primary rounded" v-if="activeJob">
        <div
          class="flex-shrink bg-light flex justify-center items-center"
          style="width: 180px; height: 198px;"
        >
          <img
            v-if="activeJob.objectThumbnail"
            :src="activeJob.objectThumbnail"
            class="object-contain w-11/12"
          />
          <img
            v-else
            src="/images/placeholder.png"
            class="object-contain w-1/2"
          />
        </div>
        <div class="px-3 py-2">
          <div class="mb-2">
            <div class="text-muted" v-text="'Object name'" />
            <router-link
              class="block font-bold text-md overflow-hidden truncate w-full"
              style="width: 190px"
              :to="
                `/library/${activeJob.objectId}?generation=${activeJob.generationId}`
              "
              v-text="activeJob.objectName"
            />
          </div>
          <div class="mb-2">
            <div class="text-muted" v-text="'Started time'" />
            <div class="font-medium">
              {{
                activeRun && activeRun.started
                  ? new Date(activeRun.started)
                  : null | formatTimestamp("yyyy-MM-dd HH:mm")
              }}
            </div>
          </div>
          <div class="mb-2">
            <div class="text-muted" v-text="'Quantity completed'" />
            <div class="font-medium">
              {{ `${activeJob.quantityCompleted} / ${activeJob.quantity}` }}
            </div>
          </div>
          <div>
            <div class="text-muted" v-text="'Est. print time'" />
            <div class="font-medium flex">
              <div v-if="activeJob.quantity > 1">
                {{ activeJob.quantity - activeJob.quantityCompleted }}
              </div>
              <div v-if="activeJob.quantity > 1" class="mx-1">x</div>
              <div>
                {{
                  (activeJob ? activeJob.estPrintTime : null)
                    | formatDuration({ short: true })
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="flex justify-between items-end mb-2 px-2 flex-grow"
      style="height: 34px"
    >
      <span
        class="chip uppercase font-medium tracking-wide"
        :class="`chip-${printerStatuses[printerStatus].chip}`"
        v-text="printerStatuses[printerStatus].text"
      />
      <div class="flex space-x-2">
        <Button
          v-if="isIdle && remotePrintingStatus === 'ENABLED'"
          color="primary"
          type="tight"
          :loading="loading"
          @click="remoteStart"
        >
          Start remote print
        </Button>
        <div
          v-else-if="isIdle && remotePrintingStatus === 'PENDING'"
          class="chip chip-disabled"
        >
          Waiting for remote access to start print
        </div>
        <Button
          v-else-if="isIdle && remotePrintingStatus === 'DISABLED'"
          color="info"
          type="tight"
          :loading="loading"
          @click="requestRemoteStart"
        >
          Request remote access to start print
        </Button>
        <Button
          v-if="isPrinting"
          color="error"
          type="tight"
          :loading="loading"
          @click="pauseJob"
        >
          Pause print
        </Button>
        <Button
          v-if="isPaused"
          color="error"
          type="tight"
          :loading="loading"
          @click="cancelJob"
        >
          Abort print
        </Button>
        <div @click="toggleLights">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-6 h-6 stroke-current"
            :class="{
              'hover:text-gray-400': lightsOn,
              'hover:text-yellow-400': !lightsOn,
            }"
            viewBox="0 0 24 24"
          >
            <path
              v-if="lightsOn"
              d="M12,2C9.76,2 7.78,3.05 6.5,4.68L7.93,6.11C8.84,4.84 10.32,4 12,4A5,5 0 0,1 17,9C17,10.68 16.16,12.16 14.89,13.06L16.31,14.5C17.94,13.21 19,11.24 19,9A7,7 0 0,0 12,2M3.28,4L2,5.27L5.04,8.3C5,8.53 5,8.76 5,9C5,11.38 6.19,13.47 8,14.74V17A1,1 0 0,0 9,18H14.73L18.73,22L20,20.72L3.28,4M7.23,10.5L12.73,16H10V13.58C8.68,13 7.66,11.88 7.23,10.5M9,20V21A1,1 0 0,0 10,22H14A1,1 0 0,0 15,21V20H9Z"
            />
            <path
              v-else
              d="M20,11H23V13H20V11M1,11H4V13H1V11M13,1V4H11V1H13M4.92,3.5L7.05,5.64L5.63,7.05L3.5,4.93L4.92,3.5M16.95,5.63L19.07,3.5L20.5,4.93L18.37,7.05L16.95,5.63M12,6A6,6 0 0,1 18,12C18,14.22 16.79,16.16 15,17.2V19A1,1 0 0,1 14,20H10A1,1 0 0,1 9,19V17.2C7.21,16.16 6,14.22 6,12A6,6 0 0,1 12,6M14,21V22A1,1 0 0,1 13,23H11A1,1 0 0,1 10,22V21H14M11,18H13V15.87C14.73,15.43 16,13.86 16,12A4,4 0 0,0 12,8A4,4 0 0,0 8,12C8,13.86 9.27,15.43 11,15.87V18Z"
            />
          </svg>
        </div>
      </div>
    </div>

    <div class="overflow-hidden h-2 flex rounded bg-light">
      <div
        :style="`width: ${activeRun ? activeRun.progress : 0}%`"
        :class="{
          'shim bg-info': printerStatus === 'PRINTING',
          'bg-warning': printerStatus === 'PAUSED',
        }"
        class="bg-opacity-75 overflow-hidden relative"
      />
    </div>
    <div
      class="w-full flex justify-between font-medium"
      :class="{
        'text-info': printerStatus === 'PRINTING',
        'text-warning': printerStatus === 'PAUSED',
      }"
    >
      <div
        v-if="activeRun && activeRun.progress"
        v-text="`${Math.min(100, Math.round(activeRun.progress))}%`"
      />
      <div v-if="activeRun && activeRun.timeLeft > 0">
        {{ activeRun.timeLeft | formatDuration }}
      </div>
    </div>
  </div>
</template>
<script>
import { printerStatuses } from "@/services/formatting";
import Button from "@/components/base/Button.vue";

export default {
  name: "Status",
  components: {
    Button,
  },
  props: {
    printer: {
      type: Object,
      required: true,
    },
    client: {
      type: Object,
      required: true,
    },
    refresh: {
      type: Function,
      required: true,
    },
    showError: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      printerStatuses,
    };
  },
  computed: {
    activeJob() {
      return this.printer.activeJob;
    },
    activeRun() {
      return this.printer.activeRun;
    },
    printerStatus() {
      return this.printer.printerState.printerStatus;
    },
    isIdle() {
      return this.printer.printerState.printerStatus === "IDLE";
    },
    isPaused() {
      return this.printer.printerState.printerStatus === "PAUSED";
    },
    isPrinting() {
      return this.printer.printerState.printerStatus === "PRINTING";
    },
    lightsOn() {
      return this.printer.printerState.lightsOn;
    },
    isActiveJob() {
      return Boolean(this.activeJob);
    },
    remotePrintingStatus() {
      return this.printer.printerState.remotePrintingStatus;
    },
  },
  methods: {
    async requestRemoteStart() {
      if (!this.isIdle) return;
      try {
        this.showError(null);
        this.loading = true;
        await this.client.requestRemotePrint();
        await new Promise((resolve) => setTimeout(resolve, 1000));
        await this.refresh();
      } catch (error) {
        console.error(error);
        this.showError(error.message);
      } finally {
        this.loading = false;
      }
    },
    async remoteStart() {
      if (this.remotePrintingStatus !== "ENABLED" || !this.isIdle) return;
      try {
        this.showError(null);
        this.loading = true;
        await this.client.startPrint();
        await new Promise((resolve) => setTimeout(resolve, 1000));
        await this.refresh();
      } catch (error) {
        console.error(error);
        this.showError(error.message);
      } finally {
        this.loading = false;
      }
    },
    async pauseJob() {
      if (!this.isPrinting) return;
      try {
        this.showError(null);
        this.loading = true;
        await this.client.pausePrint();
        await new Promise((resolve) => setTimeout(resolve, 1000));
        await this.refresh();
      } catch (error) {
        console.error(error);
        this.showError(error.message);
      } finally {
        this.loading = false;
      }
    },
    async cancelJob() {
      if (!this.isPaused) return;
      try {
        this.showError(null);
        this.loading = true;
        await this.client.abortPrint();
        await new Promise((resolve) => setTimeout(resolve, 1000));
        await this.refresh();
      } catch (error) {
        console.error(error);
        this.showError(error.message);
      } finally {
        this.loading = false;
      }
    },
    async toggleLights() {
      try {
        this.showError(null);
        this.loading = true;
        if (this.lightsOn) {
          await this.client.turnOffPrinterLights();
        } else {
          await this.client.turnOnPrinterLights();
        }
        await new Promise((resolve) => setTimeout(resolve, 1000));
        await this.refresh();
      } catch (error) {
        console.error(error);
        this.showError(error.message);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.shim::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(233, 233, 233, 1) 0,
    rgba(233, 233, 233, 0.9) 50%,
    rgba(233, 233, 233, 0.8) 100%
  );
  animation: shimmer 2.5s ease-out infinite;
  content: "";
}

@keyframes shimmer {
  100% {
    transform: translateX(0%);
    opacity: 0;
  }
}
</style>
