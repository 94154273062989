import { db, firebase, auth } from '../plugins/firebase';
import store from '@/store';

const errorDisplay = (error) => {
  if(['user-disabled', 'auth/user-not-found'].includes(error.code)) {
    return 'User not found!';
  } else if(error.code === 'auth/invalid-email') {
    return 'Invalid email!';
  } else if(error.code === 'auth/wrong-password') {
    return 'Invalid password!';
  } else if(error.code === 'auth/popup-closed-by-user') {
    return 'Popup closed by user!';
  } else if(error.code === 'auth/too-many-requests') {
    return 'Too many requests, try again later!';
  } else if(error.code === 'auth/user-not-found') {
    return 'User not found!';
  } else if(error.code === 'auth/requires-recent-login') {
    setTimeout(() => { auth.signOut(); }, 10000);
    return 'This operation requires a recent login, signing out in 10s...';
  } else if(error.code === 'auth/multi-factor-auth-required') {
    return 'Multifactor authentication required.';
  } else if(error.code === 'auth/invalid-verification-code') {
    return 'Wrong verification code, please check the code!';
  } else if(error.code === 'auth/user-disabled') {
    return 'User has been disabled!';
  } else {
    console.error(error);
    return 'Could not login right now!';
  }
};

// Reset password
const sendPasswordResetEmail = (email) => auth.sendPasswordResetEmail(email, { url: `${window.location.origin}/login`, handleCodeInApp: true });

// Sign in with email
const signInWithEmailAndPassword = async (email, password) => auth.signInWithEmailAndPassword(email, password)

const setPassword = (password) => auth.currentUser.updatePassword(password).then(() => db.collection(`organizations/${store.state.organization.id}/log`).add({
  created: {
    time: firebase.firestore.Timestamp.now(),
    uid: auth.currentUser.uid,
  },
  type: 'user',
  action: 'changePassword',
  text: `User <b>${auth.currentUser.email}</b> changed`,
  user: {
    uid: auth.currentUser.uid,
  },
}));

const updateUserName = (name) => db.doc(`users/${auth.currentUser.uid}/metaData/${store.state.organization.id}`).update({ name })


export {
  errorDisplay,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  setPassword,
  updateUserName,
}