<template>
  <div
    v-tooltip="{
      content: tooltip,
      placement: 'bottom',
      offset: 4,
      disabled: !tooltip
    }"
  >
    <button
      class="relative flex justify-center items-center focus:outline-none"
      :class="[
        !disabled && type !== 'blank' ? bgColor[color] : '',
        disabled && type !== 'blank' ? disabledBg[color] : '',
        borderColor[color],
        type !== 'blank' ? textColorWithBg[color] : textColorWoBg[color],
        types[type],
        loading || disabled ? 'cursor-not-allowed' : '',
        !loading && !disabled ? hover[type] : null
      ]"
      :disabled="loading || disabled"
      @click="$emit('click')"
    >
      <div
        v-if="loading"
        class="absolute inset-0 flex items-center justify-center"
        :class="[
          !disabled && type !== 'blank' ? bgColor[color] : '',
          types[type],
        ]"
      >
        <svg class="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </div>
      <div class="flex" :class="{'invisible': loading}">
        <slot />
      </div>
    </button>
  </div>
</template>
<script>
export default {
  name: 'Button',
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    type: {
      type: String,
      default: 'default'
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      types: {
        wide: 'rounded-md p-4 font-medium tracking-wide text-lg shadow-md',
        default: 'rounded-md px-3 py-2 font-medium tracking-wide shadow-md',
        tight: 'rounded-md px-3 py-1 font-medium shadow-md',
        xs: 'rounded-lg px-2 py-1 text-xs font-medium',
        blank: '',
      },
      hover: {
        wide: 'hover:shadow-lg',
        default: 'hover:shadow',
        tight: 'hover:shadow-lg',
        blank: 'hover:text-muted'
      },
      disabledBg: {
        primary: 'bg-gray-400',
        outline: '',
        error: 'bg-gray-400',
        warning: 'bg-gray-400',
        info: 'bg-gray-400',
        login: 'bg-black',
        none: '',
      },
      bgColor: {
        primary: 'bg-primary',
        outline: '',
        error: 'bg-error',
        warning: 'bg-warning',
        info: 'bg-info',
        login: 'bg-black',
        none: '',
      },
      borderColor: {
        primary: '',
        outline: 'border border-black',
        errorOutline: 'border border-error',
        error: '',
        warning: '',
        info: '',
        login: '',
        none: ''
      },
      textColorWithBg: {
        primary: 'text-white',
        outline: 'text-primary',
        errorOutline: 'text-error',
        error: 'text-white',
        warning: 'text-white',
        info: 'text-white',
        login: 'text-white',
        none: 'text-primary',
      },
      textColorWoBg: {
        primary: 'text-primary',
        outline: 'text-primary',
        errorOutline: 'text-error',
        error: 'text-error',
        warning: 'text-warning',
        info: 'text-info',
        login: 'text-primary',
        none: 'text-primary',
      },
    }
  },
}
</script>