<template>
  <table class="w-full">
    <thead>
      <tr>
        <td
          v-for="header in headers"
          :key="header.value"
        >
          <div class="flex">
            <div v-text="header.text" />
            <Info v-if="!!header.info" class="ml-2" :placement="header.placement" :size=4>
              <div v-html="header.info" />
            </Info>
          </div>
        </td>
      </tr>
    </thead>
    <tbody v-if="!printers.length">
      <tr>
        <td :colspan="headers.length" class="text-center">
          No printers
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr
        v-for="printer in printers"
        :key="printer.id"
        @click="$emit('printerSelect', printer)"
        :class="{
          'active': selectedPrinter === printer.id,
        }"
      >
        <td>
          {{ printer.printerInformation && printer.printerInformation.printerName ? printer.printerInformation.printerName : 'No name' }}
        </td>
        <td>
          {{ printer.departmentId ? printer.department.name : 'No department' }}
        </td>
        <td>
          {{ printer.printerInformation && printer.printerInformation.printerLocation ? printer.printerInformation.printerLocation : 'No department' }}
        </td>
        <td>
          <span
            v-if="printer.printerInformation && printer.printerInformation.status"
            class="uppercase font-medium chip tracking-wide"
            :class="`chip-${printerStatuses[printer.printerInformation.status].chip}`"
            v-text="printerStatuses[printer.printerInformation.status].text"
          />
        </td>
        <td>
          <div
            v-for="(material, index) in materials(printer.printerInformation.materials)"
            :key="`${printer.id}${index}`"
            class="text-xs"
            v-text="material"
          />
        </td>
        <td>
          {{ printer.printerInformation ? printer.printerInformation.queuePrintTime : 0 | formatDuration({ short: true, format: ['days', 'hours', 'minutes'] }) }}
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import { printerStatuses } from '@/services/formatting'
export default {
  name: 'PrinterList',
  props: {
    selectedPrinter: {
      type: String
    },
  },
  data() {
    return {
      headers: [
        { text: 'Name', value: 'name'},
        { text: 'Department', value: 'departmentName' },
        { text: 'Location', value: 'location' },
        { text: 'Status', value: 'status' },
        { text: 'Materials', value: 'materials' },
        { text: 'Queue time', value: 'queue' }
      ],
      printerStatuses
    }
  },
  computed: {
    printers() {
      return this.$store.getters['organization/getPrinters'];
    },
  },
  methods: {
    materials (materials) {
      const displayMaterials = []
      if (materials.trayA.id) {
        displayMaterials.push(this.$store.getters['core/findMaterialById'](materials.trayA.id))  
      }
      if (materials.trayB.id) {
        displayMaterials.push(this.$store.getters['core/findMaterialById'](materials.trayB.id))  
      }
      return displayMaterials.filter(i => Boolean(i))
    },
  },
}
</script>
<style scoped>
thead td {
  @apply px-2;
}
tbody td {
  @apply px-2;
  @apply py-4;
  @apply overflow-hidden;
  height: 65px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
tbody tr:hover {
  @apply cursor-pointer;
  @apply bg-light;
}
tbody tr.active {
  @apply bg-white;
  @apply shadow;
}
tbody tr td:nth-child(1) {
  width: 160px;
  max-width: 160px;
}
tbody tr td:nth-child(2) {
  width: 160px;
  max-width: 160px;
}
tbody tr td:nth-child(3) {
  width: 90px;
}
tbody tr td:nth-child(4) {
  width: 160px;
  max-width: 160px;
}
tbody tr td:nth-child(5) {
  width: 100px;
}
</style>