<template>
  <div>
    <div class="px-6 py-2 bg-fade text-muted uppercase font-medium border-b border-primary" v-text="'Live feed'" />
    <div class="relative" style="height: 300px; width: 300px">
      <div class="absolute inset-0 flex items-center justify-center">
        <img
          :src="streamSource"
          :key="streamSource"
          class="w-full h-full"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Webcam',
  props: {
    publicUrl: {
      type: String,
      required: true
    },
    token: {
      type: String,
      required: true
    }
  },
  computed: {
    streamSource() {
      return `https://${this.publicUrl}/webcam?authorization=Bearer%20${this.token}`;
    }
  },
}
</script>