<template>
  <!-- <div class="relative rounded-md flex flex-col"> -->
    <div class="fixed bottom-0 mr-3 right-0 pr-4 pb-4 flex flex-col space-y-2 overflow-hidden">
      <transition-group name="toast" tag="div" mode="out-in">
        <div
          v-for="notification in notifications"
          :key="notification.id"
          class="mb-2 toast"
        >
          <div
            class="shadow-md mb-1 rounded-md notification"
            :class="notification.type"
          >
            <div class="h-1 progress-bar rounded-md">
              <div
                class="h-full filled rounded-md"
                style="transition: width 100ms;"
                :style="{'width': notification.progress + '%'}"
              />
            </div>
            <div class="p-3">
              <div class="flex items-center">
                <div class="mr-4">
                  <svg 
                    class="fill-current h-6 w-6"
                    :class="{'animate-spin': notification.type === 'async'}"
                    viewBox="0 0 24 24"
                  >
                    <path :d="svg[notification.type]"/>
                  </svg>
                </div>
                <div class="flex-grow">
                  <div class="flex justify-between">
                    <div class="font-bold" v-text="notification.title" />
                    <div 
                      v-if="notification.type !== 'async' && !notification.persistent"
                      @click="dismiss(notification.id)"
                      class="text-muted text-xs hover:cursor-pointer ml-4"
                      v-text="'Dismiss'"
                    />
                  </div>
                  <p class="text-sm" v-text="notification.text" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition-group>
    </div>
  <!-- </div> -->
</template>
<script>

export default {
  data() {
    return {
      notifications: [],
      svg: {
        info: 'M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z',
        success: 'M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z',
        error: 'M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2C6.47,2 2,6.47 2,12C2,17.53 6.47,22 12,22C17.53,22 22,17.53 22,12C22,6.47 17.53,2 12,2M14.59,8L12,10.59L9.41,8L8,9.41L10.59,12L8,14.59L9.41,16L12,13.41L14.59,16L16,14.59L13.41,12L16,9.41L14.59,8Z',
        async: 'M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
      },
    }
  },
  methods: {
    newNotification({ id = null, persistent = false, timeout, title, type, text = null }) {
      const notification = {
        id: id || Math.random().toString(36).substring(2) + Date.now().toString(36),
        progress: 100,
        timeout,
        persistent,
        title,
        type,
        text
      }
      if(id) {
        this.notifications.splice(this.notifications.findIndex(i => i.id === id), 1, notification);
      } else {
        this.notifications.push(notification);
      }
      if(timeout > 0) this.startCountDown(notification);
    },
    startCountDown(notification) {
      const id = setInterval(() => {
        if (notification.progress <= 0) {
          clearInterval(id);
          this.notifications.splice(this.notifications.findIndex(i => i.id === notification.id), 1);
        } else {
          notification.progress -= 10 / notification.timeout;
        }
      }, 100);
    },
    dismiss(id) {
      this.notifications.splice(this.notifications.findIndex(i => i.id === id), 1)
    }
  },
  created() {
    this.$notifications.$on('notification', this.newNotification);
  },
}
</script>
<style scoped lang="scss">
.notification.info {
  @apply bg-gray-100 border-gray-500 text-gray-900;
  .progress-bar {
    @apply bg-gray-300;
    .filled {
      @apply bg-gray-800;
    }
  }
}
.notification.success {
  @apply bg-green-200 border-green-500 text-green-800;
  .progress-bar {
    @apply bg-green-300;
    .filled {
      @apply bg-green-800;
    }
  }
}
.notification.error {
  @apply bg-red-200 border-red-500 text-red-800;
  .progress-bar {
    @apply bg-red-300;
    .filled {
      @apply bg-red-800;
    }
  }
}
.notification.async {
  @apply bg-indigo-100 border-indigo-500 text-indigo-900;
  .progress-bar {
    @apply bg-indigo-300;
    .filled {
      @apply bg-indigo-800;
    }
  }
}
.toast-enter-active {
  opacity: 0;
  transition: all 1s ease-out;
  transform: translateX(100%);
}
.toast-enter-to {
  opacity: 1;
}
.toast-leave {
  opacity: 1;
  transition: all .7s ease-out;
}
.toast-leave-to {
  opacity: 0;
  transform: translateX(100%);
}
.toast {
  transition: all 1s ease-out;
}
</style>