<template>
  <table class="w-full">
    <thead>
      <tr>
        <td
          v-for="header in headers"
          :key="header.value"
        >
          <div class="flex">
            <div v-text="header.text" />
            <Info v-if="!!header.info" class="ml-2" :placement="header.placement" :size=4>
              <div v-html="header.info" />
            </Info>
          </div>
        </td>
      </tr>
    </thead>
    <tbody v-if="!queue.length">
      <tr>
        <td :colspan="headers.length" class="text-center text-muted">
          Empty queue
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr
        v-for="job in queue"
        :key="job.id"
      >
        <td>
          <Button
            type="tight"
            class="mr-2"
            @click="setJobToActive(job.id)"
            :loading="loading"
            :disabled="!isIdle"
            :tooltip="'Printer must be idle'"
          >
            Run next
          </Button>
        </td>
        <td>
          <div class="flex items-center">
            <div class="flex items-center" style="height: 50px; width: 50px">
              <img
                v-if="job.objectThumbnail"
                :src="job.objectThumbnail"
                class="object-contain w-full"
              />
              <img v-else src="/images/placeholder.png" class="object-contain w-full" />
            </div>
          </div>
        </td>
        <td>
          <router-link :to="job.objectLink">
            {{ job.objectName }}
          </router-link>
        </td>
        <td>
          <div
            v-for="(material, index) in job.materials"
            :key="`${job.id}${index}`"
            v-text="material"
          />
        </td>
        <td>
          <div>
            {{ new Date(job.created.time) | formatTimestamp('yyyy-MM-dd HH:mm') }}
          </div>
        </td>
        <td>
          {{ `${job.quantityCompleted} / ${job.quantity}` }}
        </td>
        <td>
          {{ (job.estPrintTime * job.remainingQuantity) | formatDuration({ short: true }) }}
        </td>
        <td>
          <Button
            color="none"
            type="blank"
            @click="remove(job.id)"
            :loading="loading"
          >
            <svg class="h-5 w-5 text-error fill-current" viewBox="0 0 24 24">
              <path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z"></path>
            </svg>
          </Button>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import Button from '@/components/base/Button.vue';

export default {
  name: 'Queue',
  components: {
    Button,
  },
  props: {
    printer: {
      type: Object,
      required: true
    },
    client: {
      type: Object,
      required: true
    },
    refresh: {
      type: Function,
      required: true
    },
    showError: {
      type: Function,
      required: true
    },
  },
  data() {
    return {
      loading: false,
      headers: [
        { text: '', value: 'queueActions'},
        { text: '', value: 'thumbnail'},
        { text: 'Object', value: 'object'},
        { text: 'Materials', value: 'displayMaterials' },
        { text: 'Queued', value: 'queued' },
        { text: 'Quantity', value: 'quantity' },
        { text: 'Est. print time', value: 'printTime' },
        { text: '', value: 'actions' }
      ],
    }
  },
  computed: {
    queue() {
      return this.printer.queue.map(j => {
        const materials = []
        if (j.materials.trayA) {
          materials.push(this.$store.getters['core/findMaterialById'](j.materials.trayA))  
        }
        if (j.materials.trayB) {
          materials.push(this.$store.getters['core/findMaterialById'](j.materials.trayB))  
        }
        return {
          ...j,
          objectLink: `/library/${j.objectId}?generation=${j.generationId}`,
          materials: materials.filter(i => Boolean(i)),
          remainingQuantity: j.quantity - j.quantityCompleted,
        }
      })
    },
    isAdmin() {
      return this.$store.getters['user/isAdmin'](this.printer.departmentId);
    },
    isIdle() {
      return this.printer.printerState.printerStatus === 'IDLE'
    },
  },
  methods: {
    async remove(jobId) {
      try {
        this.loading = true
        this.showError(null);
        await this.client.queueRemove(jobId)
        await new Promise(resolve => setTimeout(resolve, 1000))
        await this.refresh()
      } catch(error) {
        console.error(error)
        this.showError(error.message);
      } finally {
        this.loading = false;
      }
    },
    async setJobToActive(jobId) {
      
      try {
        this.loading = true
        this.showError(null);
        if (this.printer.activeJob) {
          await this.client.moveActiveJobToQueue()
        }
        await this.client.pushQueuedJobToActive(jobId)
        await new Promise(resolve => setTimeout(resolve, 1000))
        await this.refresh()
      } catch(error) {
        console.error(error)
        this.showError(error.message);
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>
<style scoped>
td {
  @apply px-2;
}
tbody td {
  @apply overflow-hidden;
  height: 56px;
  @apply py-0;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* tbody tr td:nth-child(1) {
  min-width: 70px;
} */
tbody tr td:nth-child(2) {
  min-width: 60px;
}
tbody tr td:nth-child(3) {
  width: 99%;
  max-width: 180px;
}
tbody tr td:nth-child(4) {
  min-width: 180px;
}
tbody tr td:nth-child(5) {
  min-width: 130px;
}
tbody tr td:nth-child(6) {
  min-width: 80px;
}
tbody tr td:nth-child(7) {
  min-width: 120px;
}
tbody tr td:nth-child(8) {
  min-width: 40px;
}
</style>