<template>
  <div id="canvas" ref="canvas" class="flex-grow flex justify-center bg-light items-center relative">
    <!-- <div class="w-full h-full flex justify-center items-center">
      <img v-if="generation && generation.thumbnail" :src="generation.thumbnail" class="object-contain" />
      <img v-else src="/images/placeholder.png" class="object-contain" />
    </div> -->
    <div v-if="!stlExists" class="inset-0 absolute flex flex-col items-center justify-center">
      <svg class="h-12 w-12 fill-current" viewBox="0 0 24 24">
        <path d="M21 17.2L6.8 3H19C20.1 3 21 3.9 21 5V17.2M20.7 22L19.7 21H5C3.9 21 3 20.1 3 19V4.3L2 3.3L3.3 2L22 20.7L20.7 22M16.8 18L12.9 14.1L11 16.5L8.5 13.5L5 18H16.8Z" />
      </svg>
      <div class="text-center text-md">No preview available</div>
    </div>
    <div v-else-if="error" class="inset-0 absolute flex flex-col items-center justify-center text-error">
      <svg class="h-12 w-12 fill-current" viewBox="0 0 24 24">
        <path d="M20.84 22.73L17.28 19.17L12.57 21.82C12.41 21.94 12.21 22 12 22S11.59 21.94 11.43 21.82L3.53 17.38C3.21 17.21 3 16.88 3 16.5V7.5C3 7.12 3.21 6.79 3.53 6.62L4.3 6.19L1.11 3L2.39 1.73L22.11 21.46L20.84 22.73M12 4.15L17.96 7.5L13.31 10.11L20.53 17.33C20.82 17.16 21 16.85 21 16.5V7.5C21 7.12 20.79 6.79 20.47 6.62L12.57 2.18C12.41 2.06 12.21 2 12 2S11.59 2.06 11.43 2.18L7.56 4.36L9 5.82L12 4.15Z" />
      </svg>
      <div class="text-center text-md">Error loading object model</div>
    </div>
    <div v-else-if="progress !== null" class="inset-0 absolute flex flex-col items-center justify-center">
      <svg class="animate-spin h-12 w-12 fill-current" viewBox="0 0 24 24">
        <path class="opacity-75" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
      </svg>
      <div class="text-center text-md">{{ `${progress}%` }}</div>
    </div>
  </div>
</template>
<script>
import {
  Scene,
  PerspectiveCamera,
  WebGLRenderer,
  Mesh,
  Color,
  MeshPhongMaterial,
  // GridHelper,
  Box3,
  Vector3,
  HemisphereLight,
  sRGBEncoding,
  DoubleSide
} from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { callable } from '@/plugins/firebase';

export default {
  name: 'ObjectView',
  props: {
    generation: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      width: null,
      height: null,
      scene: null,
      camera: null,
      renderer: null,
      stlLoader: new STLLoader(),
      model: null,
      controls: null,
      progress: 0,
      error: false
    }
  },
  computed: {
    stlExists () {
      return this.generation && this.generation.multipliers && this.generation.multipliers[1] && this.generation.multipliers[1].stl.exists
    }
  },
  methods: {
    resize () {
      this.width = window.innerWidth - document.getElementById('details').getBoundingClientRect().width
      this.camera.aspect = this.width / this.height
      this.camera.updateProjectionMatrix()
      const drawnCanvas = document.querySelector('#canvas > canvas')
      drawnCanvas.width = this.width;
      drawnCanvas.height = this.height;
      this.renderer.setSize(this.width, this.height)
      this.render()
    },
    init () {
      this.camera = new PerspectiveCamera(45, this.width / this.height, 1, 10000);
      this.camera.position.set( 0, 0, 600 );

      this.scene = new Scene();
      this.scene.background = new Color( 0xf0f0f0 );

      this.scene.add( new HemisphereLight( 0x443333, 0x111122 ) );

      // Grid
      // const helper = new GridHelper( 586, 100 );
      // helper.material.opacity = 0.8;
      // helper.material.transparent = true;
      // this.scene.add( helper );

      this.renderer = new WebGLRenderer( { antialias: true } );
      this.renderer.setSize( this.width, this.height );
      this.renderer.outputEncoding = sRGBEncoding;

      this.controls = new OrbitControls( this.camera, this.renderer.domElement );
      
      document.querySelector('#canvas').appendChild( this.renderer.domElement );
    },
    async loadObject () {
      if (this.model) {
        const selectedObject = this.scene.getObjectByName(this.model);
        this.scene.remove(selectedObject)
        this.model = null;
        this.render()
      }
      if (!this.stlExists) return;
      this.progress = 0
      this.error = false
      const {
        objectId,
        id: generationId
       } = this.generation

      const stlLoad = (url) => new Promise((resolve, reject) => {
        this.stlLoader.load(url, resolve, ({ loaded, total }) => { this.progress = Math.floor(loaded / total * 100) }, reject)
      })
      
      callable('multipliers-download', {
        organizationId: this.$store.state.organization.id,
        objectId,
        generationId,
        multiplier: 1,
        type: 'stl'
      })
        .then(stlLoad)
        .then((geometry) => {
          this.progress = null;
          return this.addObject(geometry, `${objectId}${generationId}${1}`)
        })
        .then(this.render)
        .catch((error) => {
          this.error = true
          console.error(error)
        });
    },
    addObject (geometry, id) {
      this.model = id
      var material = new MeshPhongMaterial({ 
        color: (new Color()).setHSL(7/8, 1, 0.5), 
        side: DoubleSide, 
        opacity: .9,
        transparent: true 
      });
      const mesh = new Mesh( geometry, material);
      mesh.name = id
      const box = new Box3().setFromObject(mesh)
      const size = box.getSize(new Vector3())
      mesh.position.set( 0, -size.y/2, -size.z );
      mesh.rotation.set( -Math.PI/2, 0, 0 );
      this.scene.add( mesh );
    },
    render () {
      if (this.listener) this.controls.dispose()
      this.controls.addEventListener( 'change', this.render );
      this.renderer.render( this.scene, this.camera );
    },
  },
  mounted () {
    this.width = window.innerWidth - document.getElementById('details').getBoundingClientRect().width
    this.height = this.$refs.canvas.clientHeight
    this.init()
    this.render()
    this.loadObject()
    window.addEventListener('resize', this.resize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize);
    if (this.listener) this.controls.dispose()
  },
  watch: {
    generation: {
      deep: true,
      handler() {
        this.loadObject()
      }
    }
  }
}
</script>
