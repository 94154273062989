import Vue from 'vue';

const notifications = new Vue();

const notify = ({ title, text, type = 'info', timeout = 20, persistent = false }) => notifications.$emit('notification', { title, text, type, timeout, persistent })

const notifyAsync = (promise, { title, text, successTitle, successText, failTitle }) => {
  const id = Math.random().toString(36).substring(2) + Date.now().toString(36);
  notifications.$emit('notification', { id, title, text, type: 'async', persistent: true })
  return promise
    .then(() => notifications.$emit('notification', { id, title: successTitle, text: successText, timeout: 20, type: 'success' }))
    .catch((error) => {
      notifications.$emit('notification', { id, title: failTitle, text: error.message, timeout: 20, type: 'error' })
      throw error;
    })
}

export {
  notifications,
  notify,
  notifyAsync
};