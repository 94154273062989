<template>
  <div class="py-8 px-28">
    <div class="mb-12">
      <h1 class="border-b border-primary pb-4 mb-8">Users</h1>
      <UserAdd
        @refresh-claims="refreshClaims"
        class="mb-4"
      />
      <UsersList
        :claims="claims"
        :loading="loading"
        @refresh-claims="refreshClaims"
      />
    </div>
  </div>
</template>
<script>
import { db } from '@/plugins/firebase';
import UsersList from './UsersList.vue';
import UserAdd from './UserAdd.vue';

export default {
  name: 'Users',
  components: {
    UsersList,
    UserAdd,
  },
  data() {
    return {
      claims: [],
      loading: true,
    }
  },
  computed: {
    organizationId() {
      return this.$store.state.organization.id;
    }
  },
  methods: {
    refreshClaims() {
      this.loading = true;
      return db.collection(`organizations/${this.organizationId}/claims`)
        .orderBy('email', 'asc')
        .get()
        .then(querySnap => querySnap.docs.map(i => ({
          id: i.id,
          email: i.data().email,
          role: i.data().role,
          resource: i.data().resource,
          uid: i.data().uid,
        })))
        .then((claims = []) => { this.claims = claims; })
        .finally(() => {
          this.loading = false;
        })
    }
  },
  watch: {
    organizationId: {
      immediate: true,
      handler(organizationId) {
        if(organizationId) this.refreshClaims();
      }
    }
  }
}
</script>
